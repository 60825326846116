.pos{
    position: fixed;
    background: #F6F6F6;
    height: 150px;
    top: 0;
    padding-top: 10px;
    width: 100%;
    z-index: 99;
}
.leadsIntelligence{
    width: 100%;
    display: flex;
}
.timelinet{
    display: flex;
    justify-content: center;
    margin-top: 60px;
    position: fixed;
    width: calc(100% - 100px);
    background-color: #F6F6F6;
    z-index: 2;
    height: 150px;  
}
.fixed{
    position: fixed;
    margin-top: 165px;
    background-color: #F6F6F6;
    width:calc(100% - 100px) !important;
    z-index: 4;
}
.timelinecontent{
    max-width: 55%;
    font-family: 'Raleway Regular' !important;
    color: #ED7D31;
    font-size: 14px !important;
}
.timelinecontent .p-timeline-event-connector{
    background-color:#ED7D31  !important;
}
/* .fifty{
    width:100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
}
.fiftysearch{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
} */
.fifty{
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    /* width: 55%; */
    flex: 0 0 55%;
}
.fifty .p-multiselect{
    width:20% !important;
}
.fiftysearch{
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 14px;
    flex: 1 0 200px;
    /* gap:10px; */
    /* width: 45%; */
    flex: 0 0 45%;
    margin-top: -20px !important;
}
.flex100{
    width: 100%;
    display: flex;
}
.justify-right{
    justify-content: end;
}
.timelinecontent.p-timeline-horizontal .p-timeline-event:nth-child(1) .p-timeline-event-content{
    margin-left: -10px;
}
.timelinecontent.p-timeline-horizontal .p-timeline-event:nth-child(6) .p-timeline-event-content{
    margin-left: -18px;
}
.timelinecontent.p-timeline-horizontal .p-timeline-event:nth-child(2) .p-timeline-event-content{
    margin-left: -25px;
}
.timelinecontent.p-timeline-horizontal .p-timeline-event:nth-child(3) .p-timeline-event-content,.timelinecontent.p-timeline-horizontal .p-timeline-event:nth-child(4) .p-timeline-event-content,
.timelinecontent.p-timeline-horizontal .p-timeline-event:nth-child(5) .p-timeline-event-content{
    margin-left: -40px;
}
.bgwhite{
    background-color: #fff;
}
.pading10{
    padding: 10px 0px;
}
.marginlr{
    margin:0px 10px;
}
.marginl{
    margin-left: 10px;
    font-size: 12px !important;
    font-family: 'Raleway Regular' !important;
    font-weight: 400 !important;
}
.leadsProperty .p-inputtext{
    font-size: 14px !important;
}
.field-checkbox .p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover{
    background-color: #ED7D31 !important;
    border-color: #ED7D31 !important;
}
.p-checkbox .p-checkbox-box.p-highlight{
    background-color: #ED7D31;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus{
    border-color: #ED7D31;
}
.flexwrapleads{
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
    font-size: 13px;  
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
    color: #fff;
    background-color: #eee;
}
.leadsProperty .leads-dropdown{
    width: 100%;
    border:none;
    height: 100%; 
}
.leads-dropdown .p-dropdown-trigger{
    display: none;
}
.containerloader{
    margin-right: 10px;
    margin-bottom: 10px;
}
.contentloader{
    display: flex;
    flex-wrap: wrap;
}
.statuschangestyle{
    font-family: 'Raleway Regular';
    font-size: 14px;
    font-weight: 600;
}
.enterdealval{
display: flex;
align-items: center;
gap: 5px;
margin-bottom: 10px;
}
.enterdealval .p-inputtext{
    font-family: 'Raleway Regular' !important;
    font-size: 14px !important;
    width: 126px !important;
}
.enterdealval .p-dropdown{
    font-family: 'Calibri' !important;
    font-size: 14px !important;
    width: 70px !important;
}
.enterdealval .p-dropdown-label{
    overflow: visible !important;
}
 .leadsIntelligence .p-dialog-content {
    overflow-y: visible !important;
}
.dealfooter{
    display: flex;
    justify-content: center;
    gap: 20px;
}
.dealbtn{
    width: 100px !important;
    height: 38px !important;
    color: #fff !important;
    /* width: 45%; */
    background: #ED7D31 0% 0% no-repeat padding-box !important;
    border: 1px solid #ED7D31 !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    cursor: pointer !important;
    font-size: 14px !important;
    font-weight: normal !important;
}
.ViewDetailBtn{
    font-style: normal;
    color: #303030;
    opacity: 1; 
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    font-family: 'Calibri';
    border: none;
    background-color:#fff;
}
.leadsmultiselect{
    width: 16% !important;
}
.propownerfilter .p-multiselect.p-multiselect-chip .p-multiselect-token{
    height: 20px !important;
}
.filterradiobtn .p-radiobutton{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
}
.allstyle{
    font-family: 'Raleway Regular' !important;
font-size: 12px !important;
font-weight: normal !important;
color: #44546a !important;
}
.error{
    font-family: 'Raleway Regular';
    font-size: 12px;
    font-weight: normal;
    color: #ed7d31;
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 15px
  }
  .contacterror{
    font-family: 'Raleway Regular';
    font-size: 10px;
    font-weight: normal;
    color: #ed7d31 !important;
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: -10px
  }
  .timeline li{
    font-size: 12px !important;
    max-width: 130px !important;
    font-family: "Raleway Regular" !important;
  }
 .timeline li:before{
    width: 12px !important;
    height: 12px !important;
    margin: 2px auto 10px auto !important;
  }
  .timeline li:nth-child(1):before{
    border: 3px solid #cfc8c8;;
    background: #fff;
    /* color: rgb(247, 104, 104); */
    color: #cfc8c8;
  }
  .timeline li:nth-child(1) {
    color:#cfc8c8 !important ;
}
.timeline li:nth-child(1).active-tl {
    color: rgb(247, 104, 104) !important ;
}
  .timeline li:nth-child(1).active-tl:before {
    background: rgb(247, 104, 104);
    border: 3px solid rgb(247, 104, 104);
    color: #F1F1F1;
}
.timeline li:nth-child(1).active-tl + li:after {
    background:rgb(247, 104, 104) ;
}
.timeline li:nth-child(1) + li:after {
    background:#cfc8c8 ;
}
.overlayfilter .p-inputswitch .p-inputswitch-slider:before{
    width: 15px;
    height: 14px;
    border-radius: 20px;
    margin-top: -7px;
}
.overlayfilter .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before{
    transform: translateX(1.5rem)
}
.sideheader .p-inputswitch{
   display: flex;
   align-items: center;
}
.sideheader .p-inputswitch .p-inputswitch-slider, .sideheader .p-inputswitch .p-inputswitch-slider:hover{
     height: 20px ;
    border-radius: 20px;
    background-color: #ED7D31 !important; 
    margin-top: 3px;
}
.sideheader .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
    background-color: #ED7D31;
}
.sideheader .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider{
    background-color: #ED7D31;
}
.statuscheckbox{
    display: flex !important;
    gap: 10px !important;
    font-size: 12px !important;
    margin-bottom: 15px !important;
}
.statuscheckbox .p-radiobutton .p-radiobutton-box{
    width: 18px !important;
    height: 18px !important;
}
.statuscheckboxbtn{
    display: flex !important;
    align-items: center !important;
    gap: 2px !important;
}
.statuscheckbox .p-radiobutton .p-radiobutton-box.p-highlight {
    background-color: #ed7d31 !important;
    border-color: #ed7d31 !important;
}
   
.statuscheckbox .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 10px !important;
    height: 10px !important;
}
.leadstoggle{
    height: 20px;
    margin-top: 5px !important;
    width: 60% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 5px !important;
}
.fiftysearch .p-input-icon-right{
    height: 100% !important;
    width: 25% !important;
}
.fiftysearch .p-inputtext{
    height: 100% !important;
    width: 100% !important;
}
.fiftysearch .p-multiselect{
    width: 83% !important;
    height: 100% !important;
    border-bottom-right-radius: 0px !important ;
    border-top-right-radius: 0px !important;
}
.fiftysearch .p-multiselect .p-multiselect-panel{
    max-width: 215px !important;
    min-width: 215px !important;
}
.fiftysearch .p-multiselect-panel .p-multiselect-items .p-multiselect-item{
    width: 100% !important;
    text-wrap: wrap !important;
    font-size: 12px;
    font-family: 'Calibri';
}
.fifty .p-multiselect-label,.fiftysearch .p-multiselect-label, .fiftysearch .p-inputtext{
    font-size: 11px !important;
    font-family: "Raleway Regular" !important;
    padding: 0.5rem !important;
}
.leadsarrowcontainer{
    display: flex;
    gap:5px;
}
div.arrow {
	width: 6px;
	height: 6px;
    margin-top: 2px;
	box-sizing: border-box;
	/* position: absolute; */
	/* left: 50%; */
	/* top: 0; */
	transform: rotate(135deg);
	
	&::before {
		content: '';
		width: 100%;
		height: 100%;
		border-width: 1px 1px 0 0;
		border-style: solid;
		border-color: #ed7d31;
		transition: .2s ease;
		display: block;
		transform-origin: 100% 0;
	}

	
	&:after {
		content: '';
		float: left;
		position: relative;
		top: -100%;
		width: 100%;
		height: 100%;
		border-width: 0 1px 0 0;
		border-style: solid;
		/* border-color: #6c757d; */
        border-color: #ed7d31;
		transform-origin: 100% 0;
		transition:.2s ease;
	}
	
	&:hover::after {
		transform: rotate(45deg);
		border-color: #ed7d31;
		height: 120%;
	}
	&:hover::before {
		border-color: #ed7d31;
		transform: scale(.8);
		
	}
	
}
.fiftysearch .p-multiselect-panel .p-multiselect-header .p-checkbox{
    display: none !important;
  }
  .p-input-icon-right > i:last-of-type, .p-input-icon-right > svg:last-of-type, .p-input-icon-right > .p-input-suffix {
    right: 0.75rem !important;
    color: #495057 !important;
    font-size: 12px !important;
    margin-top: -0.3rem !important;

  }
  .p-input-icon-right > i:first-of-type, .p-input-icon-right > svg:first-of-type, .p-input-icon-right > .p-input-suffix {
    right: 1.75rem !important;
    color: #495057 !important;
    font-size: 12px !important;
    margin-top: -0.3rem !important;
  }

.propownerfilter{
    display: flex;
    gap:5px;
    justify-content: space-around !important;
    align-items: center;
    /* width: 38% !important; */
    /* background-color: #ED7D31 !important; */
    border-radius: 5px !important;
    /* height: 30px !important; */
    min-width: 200px;
}
.propownerfilter .p-multiselect .p-multiselect-trigger{
    display: none;
}

.pofilterbtn{
    width: 38% !important;
}
.iconfilter{
    display: flex;
    gap: 5px !important;
    /* width: 13% !important; */
    justify-content: center;
    /* margin-right: 5px; */
    right: 35px;
    position: absolute;
}
.filterradiobtncontainer{
    display: flex !important;
    gap: 10px !important;
    font-size: 12px !important;
    margin-bottom: 5px !important;
    /* width: 38% !important; */
    min-width: 200px;
    justify-content: flex-start !important;
}
.filterradiobtncontainer .p-radiobutton .p-radiobutton-box{
    width: 11px !important;
    height: 11px !important;
}
.filterradiobtn{
    display: flex !important;
    align-items: center !important;
}
.filterradiobtncontainer .p-radiobutton .p-radiobutton-box {
    background-color: white !important;
    border-color: #ed7d31 !important;
}
.fiftysearch .p-radiobutton{
display: flex;
align-items: center;
width: 14px !important;
height: 15px !important;
}
   
.filterradiobtncontainer .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 11px !important;
    height: 11px !important;
    background-color: #ED7D31;
}
.fiftysearch .p-multiselect.p-multiselect-chip .p-multiselect-token{
width: 40% !important;
padding: 0.25rem !important;

}
.fiftysearch .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label{
    padding:0.2rem !important;
    width: 100% !important;
   
}
.fiftysearch .p-multiselect-token-label{
    width: 10px !important;
    width: 90% !important;
    overflow: hidden !important;
    display: block !important;
    white-space: nowrap !important;
    cursor: pointer !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
.fiftysearch .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon{
    margin-left: 0.20rem !important;
    font-size: 0.6rem !important;
}
.fiftysearch .p-multiselect-label-container{
    width: 100% !important;

}
.fiftysearch .p-multiselect .p-multiselect-trigger{
    width: 2rem !important;
}

.nodatastyle{
    font-size: 12px !important;
    font-weight: normal !important;
    color: #44546a !important;
    font-family: "Calibri";
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}
.overlaypanel-demo .select-product-button {
    min-width: 15rem;
}

.overlaypanel-demo .product-image {
    width: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
   .overlayfilter{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
   } 
   .fifty .p-overlaypanel {
    position: absolute;
    margin-top: -160px;
    /* margin-left: -55px; */
    left: 0 !important;
}  
.overlayfilter .p-multiselect{
    width:20% !important;
}
.overlayfilter .labelstyle {
    /* margin-bottom: 10px; */
    font-family: 'Raleway Regular';
    font-size: 11px;
    color:#ed7d31;
}
.overlayfilter .overlaydropdownContent {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-right: 10px;
    margin-bottom: 5px;
    /* grid-gap: 10px; */
    gap: 5px;
    /* margin-top: 10px; */
}
.overlayfilter .p-multiselect{
    width: 100% !important;
    height: 27px !important;
}
.overlayfooter{
    display: flex;
    justify-content: center;
    gap: 10px;
}
.leadsIntelligence .p-overlaypanel .p-overlaypanel-close {
    background-color: #ED7D31;
}
.leadsIntelligence .p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #ED7D31;
    color: #ffffff;
}
.overlaybtn {
    /* margin: 15px 0px 5px 0px; */
    height: 30px;
    background: #ed7d31;
    border-radius: 3px;
    border: none;
    font-family: 'Calibri';
    font-size: 14px;
    color: #ffffff;
    margin-right: 1px;
    cursor: pointer;
    min-width: 90px;
}
.leadsIntelligence .MuiButton-root{
    border: 1px solid #ED7D31;
    color: #ED7D31;
    text-transform: capitalize;
    height: 80%;
    font-size: 12px !important;
    font-family: 'Raleway Regular' !important;
    font-weight: 600 !important;
    padding-bottom: 3px;
}
.advfilterimgdiv{
    background-color: #ED7D31;
    color: white;
    cursor: pointer;
    height: 30px !important;
    display: flex;
    align-items: center;
    margin-top: 2px;
    border: 1px solid;
    /* width: 30px !important ; */
    /* position: absolute; */
    /* right: 0; */
    font-size: 12px;
    padding: 5px;
    /* margin-left: -10px; */
    /* top: -95px; */
    justify-content: flex-start;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}
.advfilterimgdiv:hover{
    background-color: #ED7D31;
    color: white;
    animation:ease-in 3s ;
}
.obimg{
    font-size: 14px !important;
    padding-right: 3px;
}
.advfilterimgdiv .advfiltertooltiptext{
    visibility: hidden;
    width: 100px;
    font-size: 10px;
    font-family: 'Raleway Regular';
    font-weight: 600;
    background-color: rgb(233, 230, 230);
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 2px;
    right: 120%;
    color: #212529;
}
.advfilterimgdiv .advfiltertooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent rgb(233, 230, 230);
  }
.advfilterimgdiv:hover .advfiltertooltiptext{
    visibility: visible;
}
.filterCalenderBox{
    border-radius: 10px !important;
    margin-right: 10px !important;
    width: 100%;
  }
  .filterCalcFix {
    display: flex !important;
    align-items: center;
    position: absolute;
    border: none;
    border-radius: 23px;
    margin-right: 50px;
    top: 237px;
    right: 0;
  }
  .filterCalenderBox .p-button,.filterCalenderBox .p-button:hover{
    padding: 0;
    background-color: #ED7D31 !important;
    border: 1px solid #ED7D31 !important;
    font-size: 10px !important;
  }
  .filterCalenderBox .p-button.p-button-icon-only{
    padding: 0;
  }
  .leadsIntelligence .p-calendar {
    width: 100% !important;
  }
.MuiPickersDatePickerRoot-toolbar {
    /* background: transparent linear-gradient(243deg, #ff2700 0%, #fbb700 100%) 0%
      0% no-repeat padding-box !important; */
      background-color: #ED8036 !important;
    /* height:30px !important; */
  }
.overlayfilter .p-datepicker table , .leadsIntelligence .p-datepicker table td{
    padding:0px ;
    font-size: 12px;
    font-family: "Calibri";
}
.overlayfilter .p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #ed7d31;
}
.overlayfilter .p-datepicker table td > span.p-highlight {
    color: #ffffff;
    background: #ed7d31;
}
.overlayfilter .filterCalenderBox .p-datepicker .p-datepicker-header .p-datepicker-next,
.overlayfilter .filterCalenderBox .p-datepicker .p-datepicker-header .p-datepicker-prev{
    font-size: 12px;
    color: #ffffff;
}
.overlayfilter .p-datepicker .p-datepicker-header {
    padding: 2px;
    color: white;
}
  
.fifty .p-inputtext{
    color:#212529 !important;
    padding: 0.5rem !important;
}
.overlayfilter .propunitInput .p-inputtext{
    color:#212529 !important;
    padding: 0.5rem !important;
    width: 20%;
    text-align: center;
    font-size: 12px;
}
.overlayfilter .p-multiselect-panel .p-multiselect-header {
    grid-gap: 5px !important;
    gap: 5px !important;
}
.overlayfilter .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    margin-top: -0.35rem !important;
    font-size: 0.7rem !important;
}
.overlayfilter .clrfilter {
    color: #495057 !important;
    font-size: 0.7rem !important;
    cursor: pointer !important;
    width: 5% !important;
}
.overlayfilter .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    margin-left: 0px !important;
    width: 15px !important;
    height: 12px !important;
}
.overlayfilter .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    width: 100% !important;
    text-wrap: wrap !important;
    font-size: 12px;

}
.overlayfilter .rbprop .p-multiselect .p-multiselect-label.p-placeholder{
    font-size: 11px !important;
    font-family: 'Raleway Regular';
}
.overlayfilter .p-multiselect .p-multiselect-label{
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    font-size: 11px;
}
.overlayfilter .p-multiselect-label-container {
    width: 100% !important;
}
.overlayfilter .p-multiselect .p-multiselect-trigger {
    width: 2rem !important;
}
.overlayfilter .p-multiselect .p-multiselect-panel {
    max-width: 100% !important;
}
.overlayfilter .overlayfiltercalendar{
    display: flex;
    gap:50px;
}
.overlayfilter .overlaydatestyle {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
}
.overlayfilter .MuiFormControl-root{
    width: 60%;
}
.overlayfilter .MuiInputBase-input {
    font-size: 11px !important;
    text-align: center !important;
    cursor: default !important;
    color: #898e93 !important;
    padding: 0px 2px;
}
 .MuiPickersToolbar-toolbar{
    height: 50px !important;
    background-color: #ED7D31 !important;
}
.MuiPickersToolbar-toolbarLandscape{
    max-width: 100% !important;
}
 .MuiPickersToolbarText-toolbarBtnSelected{
font-size: 16px !important;
font-weight: 600 !important;
}
.MuiTypography-colorPrimary {
    color: #ED7D31 !important;
}
.MuiTypography-subtitle1 {
    font-size: 12px !important
}
.MuiPickersYear-yearSelected {
    margin: 0 !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}
.MuiPickersBasePicker-pickerView {
    min-width: 100px !important;
    max-height: 150px !important;
    min-height: 150px !important;
}
.MuiPickersDay-daySelected {
    background-color: #ED7D31 !important;
}
.MuiPickersDay-current {
    color: #ED7D31;
    font-weight: 600;
}
.slidertooltip > .p-tooltip-text{
    background-color: #ed7d31 !important;
    color: #fff !important;
    display: flex;
    height: 10px !important;
    font-size: 8px !important;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    padding: 8px 5px  !important;
}
.slidertooltip > .p-tooltip-arrow{
 display: none !important;
}
.overlaydatediv{
    width: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid rgb(206, 212, 218);
    height: 29px;
    border-radius: 3px;
}
 .MuiPopover-root{
    z-index: 99999 !important;
}
 .MuiPickersYearSelection-container{
    height: 100% !important;
}
.MuiPickersBasePicker-containerLandscape{
    display: flex !important;
    flex-direction: column !important;
}
.overlaydropdownContent .p-chips .p-chips-multiple-container .p-chips-token {
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    background: #ED7D31;
    color: #ffffff;
    border-radius: 4px;
}
.overlaydropdownContent .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: 0.5rem;
    font-size: 10px;
}
.overlaydropdownContent .p-chips-token-label{
    font-size: 11px;
    font-family: 'Calibri';
}
.overlaydropdownContent .p-chips-multiple-container{
display: flex;
flex-wrap: wrap;
gap:5px 0px;
}
.overlaydropdownContent .p-chips .p-chips-multiple-container .p-chips-input-token {
    display: none;
}
.timeline{
    position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  }
  .timeline li{
    list-style: none;
    flex: 1;
    float: left;
    max-width: 200px;
    position: relative;
    text-align: center;
    color: #ED7D31;
    font-size: 14px;
  }
  .timeline li:before{
    content: "";

    /* counter-increment: test; */
    /* content: counter(test); */
    width: 20px;
    height: 20px;
    border: 3px solid #ED7D31;
    border-radius: 50%;
    display: block;
    text-align: center;
    line-height: 0px;
    margin: 0 auto 10px auto;
    background: #fff;
    color: #ED7D31;
    transition: all ease-in-out .3s;
    cursor: pointer;
  }
  .timeline li:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: grey;
    top: 10px;
    left: -50%;
    z-index: -999;
    transition: all ease-in-out .3s;
  }
  .timeline li:first-child:after{
    content: none;
  }
  .timeline li.active-tl{
    color: #555555;
  }
  .timeline li.active-tl:before{
    background: #ED7D31;
    color: #F1F1F1;
  }
  .timeline li.active-tl + li:after{
    background: #ED7D31;
  }
  .leadsProperty .leadscard{
    max-width: calc(33% - 3px);
    flex: 1 1 calc(33.333% - 16px);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between !important;    
}
.leadsProperty{
    margin-top: 230px;
    display: flex;
    flex-wrap: wrap;
  gap: 10px  !important; /* Space between cards */
  padding: 0px 3px;
}

.advfilterselected{
    font-size: 12px !important;
    font-family: 'Calibri' !important;
    color: #ED7D31;
    text-align: center;
    display: flex;
    flex-direction: row !important;
    justify-content: center;
    gap:20px !important;
}
  @media (max-width: 1200px) {
    .fixed{
        width: calc(100% - 110px) !important;
    }
  }
  @media (max-width: 1120px) {
    .leadsProperty .leadscard{
        max-width: calc(50% - 5px);
    }
    .leadsProperty .leadscard{
        flex: 1 1 calc(33.333% - 5px);
    }
  }
  @media (max-width: 820px) {
    .pos{
        position: relative !important;
    }
    .timelinet{
        position: relative !important;
        margin-top:-10px!important;
        width: 90% !important;
        max-height: 100px !important;
       }
       .fixed{
        position: relative !important;
        width: calc(100%) !important;
        margin-top: 10px !important  ;
       }
       .leadsProperty{
        margin-top: 30px !important;
       }
    .leadsProperty .leadscard{
        max-width: calc(50%-5px);
       }
    .leadsProperty .leadscard{
        flex: 1 0 calc(50%);
    }
       /* .advfilterimgdiv{
        top:-100px !important;
       } */
  }
  /* @media (max-width: 700px){
    .leadsProperty .leadscard{
        max-width: calc(100%);
       }
    .leadsProperty .leadscard{
        flex: 1 0 calc(100%);
    }
  } */
  @media (max-width: 650px) {
    /* .advfilterimgdiv{
        top:-30px !important;
       } */
    .leadsProperty .leadscard{
        max-width: calc(100%);
       }
    .leadsProperty .leadscard{
        flex: 1 0 calc(100%);
    }
    .fixed{
        display: flex !important;
        flex-wrap: wrap !important;
        flex-direction: column !important;
        gap:20px !important;
    }
     
    .fiftysearch,.fifty{
        flex: 0 0 auto;
        width: 100%;
        }
       .leadsProperty{
        margin-top: 60px !important;
       }
    .marginlr{
        max-width: 100px !important;
      }
    }