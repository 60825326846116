.overflowscroll{
    overflow-x: scroll;
    -webkit-box-shadow: 0px 0px 2px 0px #00000029;
    -moz-box-shadow: 0px 0px 2px 0px #00000029;
    box-shadow: 0px 0px 2px 0px #00000029;
    height: 35px;
    background-color: #fff;
    margin-bottom: 10px;
    margin-top: 60px;
    display: flex;
    width: 100% !important;
}
.livecontainer{
  -moz-animation: marquee 1000s infinite linear;
  -webkit-animation: marquee 1000s infinite linear;
  animation: marquee 1000s infinite linear;
  position: relative;
  height: 35px;
  line-height: 35px;
  display: flex;
}
.livecontainer:hover{
  -moz-animation-play-state: paused;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
@keyframes marquee {

  0% {
     left: 0;
  }

  100% {
     left: 5%;
     transform: translateX(-70%);
  }

}
.livedata{
  font-size: 13px !important;
  font-family: 'Calibri' !important;
  cursor:  pointer !important;
}
.overflowscroll::-webkit-scrollbar {
  display: none;
}
.clearbtn{
  background-color: #fff;
  text-decoration: underline;
  border-style: none;
  cursor: pointer;
  font-weight: 400;
  color:#495057;
  font-size: 12px;
  font-family: 'Raleway Regular';
}
.marketpage{
    /* margin-top: 10px; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  } 
.overflowtop{
     /* animation: slide 80s ease-in 0s  infinite; */
     position: relative;
     height: 35px;
     line-height: 35px;
     width: 100%;
     display: flex;
     /* animation-timing-function: linear; */
}
.overflowtop:first-of-type{
  margin-left: 90px;
}
.overflowtop div{
    display: flex;
    /* overflow: hidden; */
    margin: 0px;
    min-width: max-content;
    width: 100%;
    font-family: 'Calibri'; 
}
@keyframes scrollt {
	100% { top: -100%; }
}
@keyframes slide {
    0% {
      transform: translate3d(10, 0, 0);
    }
    100% {
      transform: translate3d(-1500px, 0, 0); /* The image width */
    }
  }
.live{
   position: absolute;
   z-index: 9;
  }
  .dot{
    margin: 0px 10px;
  }
  .feeds{
    -webkit-box-shadow: 0px 0px 2px 0px #00000029;
    -moz-box-shadow: 0px 0px 2px 0px #00000029;
    box-shadow: 0px 0px 2px 0px #00000029;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    background-color: #f6f6f6;
    width: 100%;
    box-sizing: border-box;
    /* height: calc(50%); */
    height: calc(100%);
  }
  .milinechart{
    width: 100%;
    height: calc(50%);
    display: grid;
  }
  .mcharts{
    box-sizing: border-box;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 2px 0px #00000029;
    -moz-box-shadow: 0px 0px 2px 0px #00000029;
    box-shadow: 0px 0px 2px 0px #00000029;
  }
  .mimap{
    width: 42%;
    box-sizing: border-box;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 2px 0px #00000029;
    -moz-box-shadow: 0px 0px 2px 0px #00000029;
    box-shadow: 0px 0px 2px 0px #00000029;
    height: calc(100%);
  }
  .feeds .title{
    -webkit-box-shadow: 0px 0px 2px 0px #00000029;
    -moz-box-shadow: 0px 0px 2px 0px #00000029;
    box-shadow: 0px 0px 2px 0px #00000029;
    margin: 0px;
    padding: 15px 10px;
    border-bottom: 1px solid #00000029;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
  }
  .feeds .title .p-calendar{
width:130px;
  }
  .feeds .title .p-calendar .p-button{
  background-color: #eee;
  color: black;
  border-color: #717171;
  padding: 0px;
      }
      .feeds .title .p-calendar .p-inputtext{
        border-color: #717171;
        font-size: 14px;
        padding: 2px;
      }
  .mcharts:nth-child(2){
    box-shadow: none;
  }
  .mcharts:nth-child(even){
    margin-right: 0px;
  }
  .mchartsFourchart p{
    display: inline;
  }
  .mcharts .title{
    margin: 0px;
    padding: 5px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    background-color: #fff;
    letter-spacing: normal;
  }
  .mimap .title .p-dropdown{
    width:130px;
      }
      .mimap .title .p-dropdown .p-dropdown-trigger{
      background-color: #eee;
      color: black;
      border-color: #717171;
      padding: 0px;
          }
          .mimap .title .p-dropdown .p-inputtext{
            border-color: #717171;
            font-size: 14px;
            padding: 2px;
          }
          .custom-skeleton {
            border: 1px solid var(--surface-d);
            border-radius: 4px;
        }
        .custom-skeleton ul {
            list-style: none;
        }
        .liveloader{
          margin: 2px !important;
        }      
.milinechart .p-datepicker .p-monthpicker, 
.milinechart .p-datepicker .p-datepicker-header,
.milinechart .p-datepicker .p-datepicker-header .p-datepicker-title select{
  margin: 0;
  font-size: 11px !important;
  font-family: "Raleway Regular" !important;
  font-weight: 400 !important;
  padding: 0;
}
.milinechart .p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #ffffff;
  background: #ED7D31 !important;
}
.milinechart .p-calendar-w-btn .p-inputtext {
  border-right:1px solid white ;
  width: 100px !important;
}
 .milinechart .p-inputtext:enabled:focus ,.milinechart .p-inputtext:enabled:hover {
  outline: none !important;
  box-shadow: none !important;
  border-left: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-right: 1px solid white !important;
}
.milinechart .p-button:focus,.milinechart .p-button:hover{
  outline: none !important;
  box-shadow: none !important;
  background-color: white !important;

}
.milinechart .p-calendar{
  height: 25px !important;
}
.milinechart .p-calendar-w-btn .p-datepicker-trigger {
  color: black !important;
  width: 100% !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: white;
  padding: 0 5px !important;
 border-right: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
  border-left: 1px solid white !important;
}
.mimap .title{
  z-index: 99 !important;
  position:  relative;
}
 .mapcontainer{
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  height:88% !important;
  overflow: hidden !important;
  z-index: 1 !important;
}
.marketpagecontent{
  display: flex;
  flex-wrap: wrap;
  height: 80vh;
  gap:10px;
  justify-content:flex-start;
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(100vh - 130px); /* Full height minus 100px from top and bottom */
  overflow: hidden; /* Prevent scrollbars */
}
.mchartsFourchart {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 22%;
  /* overflow: hidden; */
  background-color: #f6f6f6;
  width: 100%;
  grid-gap: 5px;
  gap: 0px 5px;
}
.mapsidecontainer{
  width: 58%;
  max-height: calc(100%);
  display: flex;
  flex-direction: column;
  gap:10px ;
}
.michartContainer{
  display: flex !important;
  gap:10px !important;
  flex-direction:row !important;
  width:100% !important;
  min-height: 78% !important;
  max-height: calc(80vh - 150px);
  /* max-height: 85% !important; */
}
.chartFlexContainer:nth-child(1){
  flex: 1 0 49%;
}.chartFlexContainer:nth-child(2){
  flex: 1 0 49%;
}
.micalendar .p-datepicker .p-monthpicker .p-monthpicker-month{
padding: 0.3rem !important;
}
.selectedStateStyle{
  font-size: 12px !important;
  /* color: #ED7D31 !important; */
  color: #495057 !important;
  margin-right: 10px !important;
}
@media (max-width: 1024px) {
  .overflowscroll{
    margin-top: 10px;
  }
    .marketpagecontent{
        overflow: visible;
    }
    .mchartsFourchart{
     gap:0px 10px;
    }
}

@media (max-width: 768px) {
  .overflowscroll{
    margin-top: 10px;
  }
    .marketpagecontent {
        overflow: visible;
    }
    .mchartsFourchart{
      gap:0px 10px;
     }

}
@media (max-width: 550px) {
  .overflowscroll{
    margin-top: 10px;
  }
    .marketpagecontent {
        overflow: visible;
    }
    .michartContainer{
      display: flex;
      flex-wrap: wrap;
     }
      .chartFlexContainer{
       /* width: 100%; */
       flex-basis:calc(100%);
       margin-bottom: 5px;
      }
  }