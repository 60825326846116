.newsfeed{
display: flex;
flex-direction: column;
font-size: 13px;
font-family: 'Calibri';
color:#44546a ;
background-color: #fff;
/* max-height: calc(30vh - 60px); */
max-height: 85% !important;
justify-content: space-between;
overflow: auto !important;
width: 100%;
}
/* .newsfeed::-webkit-scrollbar{
    display: none;
  } */
.feedcontent {
    display: flex;
    align-items: center;
    -webkit-box-shadow: 0px 0px 2px 0px #00000029;
    -moz-box-shadow: 0px 0px 2px 0px #00000029;
    box-shadow: 0px 0px 2px 0px #00000029;
    padding: 5px 0px;
}
.ficon{
    display: flex;
    justify-content: center;
    width: 10%;
    padding: 1%;
}
.ficon i{
    margin: 0px 5px ;
}
/* .feedsdate{
width: 20%;
padding: 0 2%;
} */
.feedsp{
width: 90%;
color: #44546a;
font-family: 'Calibri';
padding: 0px 2%;
}
.newsfeed .read-more-button{
color: #F98436;
}
.feedcontent p{
    margin: 0px;
}
.news-feed-item {
    border-bottom: 1px solid #e0e0e0;
    padding: 1rem;
    font-family: 'Calibri';
    color: #44546a;
}

.headline {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    text-align: center;
}

.market-name {
    font-size: 0.9rem;
    text-align: center;
    margin: 0.5rem 1rem;
}

.description {
    font-size: 0.9rem;
    color: #44546a;
    font-family: 'Calibri';
    margin: 0 3rem;
}

.date {
    font-size: 0.8rem;
    color: rgb(173, 168, 168);
    margin: 0.5rem 1rem;
    text-align: end;
}
.newsfeedsdialog{
    display: flex;
    justify-content: center;
}
.newsfeedsdialog .p-dialog .p-dialog-header{
    padding: 10px 1.5rem !important;
}
.newsfeedsdialog .p-dialog-content{
    overflow-y: hidden !important;
    padding: 10px !important;
    min-height: 100% !important;
    min-width: 100% !important;
}