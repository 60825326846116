
.p-menuitem{
  outline: none;
  border:none
}

.p-menuitem:hover{
  outline: none;
  background: none !important;
  border:none
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: none !important;
}
.p-menubar .p-menuitem-link:focus {

  box-shadow: none !important;
}


.logoutContainer{
  padding: 10px
};


.dropbtn {
    color: white;
    padding: 16px;
    font-size: 14px;
    border: none;
  }
  .p-ai-center {
    height: 70px;
  }
  
  .dropdownheader {
    /* display: inline-block;
    position: relative; */
  } 
  /* .org{
    position: fixed;
    left: 25px;
    bottom:0;  
    height: 60px; 
    z-index: 999;
     cursor: pointer;  
    
  } */
  /* .orgiconlabel{
    width: 65px;
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size:10px;
     color: #ED7D31;
     background: none;
     word-break: break-all;
     position: absolute;
     bottom: 5px;
     left:-10px;
  } */
  /* .orgbtn{
    cursor: pointer;

  }
  .org .p-listbox{
    min-width: 100px !important;
    bottom: 26px;
    left:45px !important;
    position: absolute !important;
  }
  .org .p-listbox-list-wrapper{
    max-height: 100px !important;
    overflow-y: auto !important;
  }
  .org .p-listbox .p-listbox-list .p-listbox-item {
    padding: 0.5rem 0.5rem;
    border: 0 none;
    color:#8D8D8D;
    font-size: 12px;
    font-family: 'Raleway Regular';
  }
  .org .p-listbox .p-listbox-list .p-listbox-item.p-highlight{
    background-color: #F6F6F6 !important;
    color: #8D8D8D !important;
  } */
 
   .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    top: 60px;
    right: 0;
    /* left: 15px; */
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 9999;
  }
  
  .dropdown-content a {
    color: #8D8D8D;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {filter: brightness(60%);}
  
  .dropdownheader:hover .dropdown-content {display: block;} 


  
  