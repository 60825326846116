.leadscard .viewDetails,.lastSale,.leadscard .title ,.leadscard .ownerName,.leadscard .address{
    font-family: 'Calibri';
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 0px;
    color: #303030;
    opacity: 1;
}
.progress{
    display: flex;
    background: green;
    border-radius: 10px;
    width: 10px;
    height: 10px;
    margin-right: 10px;
}
.dateStatus{
    font-family: 'Raleway Regular';
    border-radius: 2px;
    font-size: 8px !important;
    font-weight: 600;
    color: #ED7D31;
    display: flex;
    justify-content: right;
    text-align: right;
}
.tooltiptext{
    display: flex;
    position: absolute;
    z-index: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: green;
    color: white;
    font-size: 8px;
    font-weight: 600;
    font-family: "Raleway Regular";
    padding: 0 3px 1px 3px;
}
.progress:hover  .tooltiptext{
    visibility: visible;
}
.leadscard{
    background: #FFFFFF 0% 0% no-repeat padding-box;     
    border-radius: 3px;
    box-shadow: 0px 3px 6px #00000029;
    opacity:1;
    }
    .leadscard .header{
    display: flex;
    } 
    .leadscard .header .main-content{
        margin: 0px;
        width: 70%;
        padding:8px;      
    }
    .side-content{
        width: 30%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;     
    }
    .leadscard .title{
        line-height: 14px;
        margin: 0px;
        padding:8px;
        font-weight: 600 !important;
        }
   .state{
        color: #303030;
        opacity: 1;
        line-height: 5px;
        margin: 0px;
        font-size: 14px;
        font-weight: normal;
        padding:8px;
        font-family: 'Calibri';
        
    }
    .lastSale{
        line-height: 5px;
        margin: 0px;
        padding:8px;
    }
    .leadscard .ownerName{
        line-height: 18px;
        margin: 0px;
        padding:8px; 
    }
    .leadscard .address{
        margin: 0px;
        margin-top: 5px;
        padding:8px;
    }
    .leadscard .location{
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: -5px;
    }
    .leadscard .header .sidecontent{
        margin-left: 20px;
        width: 35%;
        font-size: 14px;
        font-weight: 600;  
        text-align: left;
        font-style: normal;
        letter-spacing: 0px;
        color: #303030;
        padding:8px;
        font-size: 16px;
        font-weight: 600;
        font-family: 'Raleway Regular';
    }
    .IntelLeads .content{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 12px 12px;
        width: 100%;     
    }
    .IntelLeads .p-dropdown:hover,.IntelLeads .p-dropdown.p-focus,.IntelLeads .p-dropdown:not(.p-disabled).p-focus {
        box-shadow: none !important;
        border: none !important;
    }
    .IntelLeads .p-dropdown-item{
        font-size: 12px !important;
        font-family: 'Raleway Regular' !important;
    }
    .leadscard .dropdown:nth-child(1){
        width:  28%;
        text-align: start;
    }
    .leadscard .dropdown:nth-child(2){
        width:  32%;
        text-align: start;
    }
    .leadscard .image{
        margin: 10px ;
        width: 90px;
        height: 26px;
        margin-left: 80px;
       }
       .leadscard .location-icon{
        width: 16px;
        height: 19px;
        margin: 10px -5px 0px 6px;
       }
    .ilrbtable td:nth-child(3)  {
        font-family: 'Calibri' !important;
            }
    .content .p-inputtext{
        color: #ED7D31 !important;
        font-size: 11px !important;
        font-weight: normal !important;
        padding: 0 0.25rem !important;
       text-decoration: underline !important;
        font-family: "Raleway Regular";
    }
    .leadscard .rbbutton{
        color: #ED7D31 !important;
        padding: 0;
        text-align: end;
        width: 40%;
        border: none;
        text-decoration: underline;
        background-color: white;
        font-family: "Raleway Regular";
        opacity: 1;
        cursor: pointer;
        font-size: 11px !important;
        font-weight: normal !important;
    } 
   .leadscard .viewDetails{
    letter-spacing: 0px;
    color: #303030;
    opacity: 1;
    margin-right: 6px;
    text-decoration: underline;
    font-weight: 600 !important;
    cursor: pointer;
    border: none;
    background-color: #fff;
   }
.buyerstb .rbbuyerstb {
    min-height: 200px !important;
    max-height: 400px !important;
    width: 100% !important;
    height: 100% !important;
}
.buyerstb .p-datatable-wrapper , .rbbuyerstb .p-datatable-wrapper{
    height: 400px !important;
    max-height: 400px !important;
}
.expandtable{
    max-height: fit-content !important;
    width: 100% !important;
    height: 100% !important;
}
.expandtable .p-datatable-wrapper{
    height: fit-content !important;
    max-height: fit-content !important;
}
.buyerstb th , .rbbuyerstb th{
    position: sticky !important;
    top: 0 !important;
    border-style: none !important;
    z-index: 1 !important;
    padding:0.5rem;
}
.buyerstb .p-column-title, .rbbuyerstb .p-column-title{
    font-family: 'Raleway Regular';
    font-size: 12px !important;  
    color: #303030 !important;
}
.error{
    font-family: 'Raleway Regular';
    font-size: 12px;
    font-weight: normal;
    color: #ed7d31 !important;
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 15px
  }
.buyerstb td, .rbbuyerstb td{
    font-family: 'Calibri';
    font-size: 12px !important;
    color: #303030 !important;
    padding:0.5rem;
}
.buyerstb .p-inputtext, .rbbuyerstb .p-inputtext{
    padding: 0.5rem 0 !important;

}
.expandtable{
    z-index: 0 !important;
}
.orders-subtable .p-datatable .p-datatable-tbody > tr > td{
    padding: 0px 10px;
}
.orders-subtable .p-datatable .p-datatable-thead > tr > th {
padding: 5px 10px;
}
.sideheader{
    height: 100% !important;
}
.view-details-div 
.view-details-style{
    line-height: 10px !important;
    font-size: 13px !important;
    font-family: 'Calibri' !important;
}
.view-details-style p {
    width: 100% !important;
    color: #303030 !important;
}
.view-details-style span{
    max-width: 70%;
    display: flex;
    justify-content: right;
    font-weight: 600;
    float: right;
    text-align: end;
    align-items: flex-end;
}
.view-details-div{
    height: 100% !important;
    overflow-y: auto !important;
    padding-right: 10px;

}
.dealfooter{
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}
.dealbtn{
    width: 100px !important;
    height: 38px !important;
    color: #fff !important;
    background: #ED7D31 0% 0% no-repeat padding-box !important;
    border: 1px solid #ED7D31 !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    cursor: pointer !important;
    font-size: 14px !important;
    font-weight: normal !important;
    justify-content: center !important;
}
.addbtn{
    width: 50px !important;
    height: 20px !important;
    color: #fff !important;
    background: #ED7D31 0% 0% no-repeat padding-box !important;
    border: 1px solid #ED7D31 !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    cursor: pointer !important;
    font-size: 12px !important;
    font-weight: normal !important;
    justify-content: center !important;
    align-self: center !important;
}
.addchangestyle{
    font-family: 'Raleway Regular';
    font-size: 14px;
    font-weight: 600;
}
.enternotes{
display: flex;
justify-content: center;
margin-bottom: 10px;
}
.enternotes .p-inputtext{
    font-family: 'Raleway Regular' !important;
    width: 250px !important;
    font-size: 14px !important;
}
.expandtable .p-datatable-tbody > tr > td:nth-child(3) {
    color:#ED7D31 !important;
}
.alignnotes{
    display: flex;
    flex-direction: column;
align-items: center;
}
.rbbuyerstb td:nth-child(2),.rbbuyerstb th:nth-child(2){
    width: 100px;
    text-align: center !important;
 }
 .leadsaddnotesbtn{
    display: flex !important;
    color:#fff !important;
    background: #ED7D31 0% 0% no-repeat padding-box !important;
    border: 1px solid #ED7D31 !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    cursor: pointer !important;
    font-size: 12px !important;
    font-style: "Raleway Regular";
    font-weight: normal !important;
}
.leadbtndiv{
    display: flex !important;
    justify-content: flex-end !important;
    padding: 2px 0;
}
.leadstatuslabel{
    font-family: 'Raleway Regular';
    font-size: 11px;
    font-weight: 400;
    color: black;
    padding: 0 0.2rem;
}
.view-details-div .p-accordion-header-text {
    line-height: 1;
    font-size: 13px;
    color: #ED7D31;
    font-weight: 600;
    font-family: 'Calibri' !important;
}
.view-details-div .p-accordion .p-accordion-content{
    border: none !important;
    padding: 0 !important;
}
.view-details-div .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon{
    color: #ED7D31 !important;
    font-size: 0.7rem !important;
    margin-top: -8px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-right: 0px !important;
}
.view-details-div .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link ,
.view-details-div .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link,
.view-details-div .p-accordion .p-accordion-header .p-accordion-header-link,
.view-details-div .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    border: none;
    background-color: white; 
}
.view-details-div .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none !important;
    border: none;
    background-color: white; 

}
.view-details-div .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0 !important;
    margin-top: 12px !important;
}
.view-details-div .notesdiv{
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    padding: 1rem 0;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
    margin-top: 12px;
}
.view-details-div .addnotesicon{
    display: flex;
    gap:5px;
    width: 5%;
}
.view-details-div .notesicon{
    color: white !important;
    font-size: 0.7rem !important;
    cursor: pointer;
}
 .addnotesinput .p-inputtext {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    width: 100% !important;
}
.view-details-div .addnotesinput{
   width: 85%;
}
.view-details-div .addnotesdiv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    border-radius: 4px !important;
    gap:7px;
    width: 100%;

    background-color: #ED7D31;
}
.view-details-div .viewnotesdiv{
    width: 100%;
    height: 30vh;
    margin-top: 10px;
    border-radius: 4px !important;
    overflow-y: auto;

}
.view-details-div .p-datatable-wrapper{
    height: 100% !important;
    overflow-x: auto;
}
.viewnotesdiv th{
position: sticky !important;
top: 0 !important;
border-style: none !important;
z-index: 1 !important;
}
.viewnotesdiv .p-column-title,.viewnotesdiv  td{
    font-family: "Calibri" !important;
    font-size: 12px !important;
}
.view-details-div th:nth-child(2) {
    width: 100px;
    text-align: center !important;
}
.view-details-div .p-datatable .p-datatable-tbody > tr > td{
    text-align: left !important;
    padding: 0.3rem !important;
}
.view-details-div .p-datatable .p-datatable-thead > tr > th {
    text-align: left !important;
    padding: 0.5rem !important;
}
.editnotesinput .p-inputtext{
    width: 90% !important;
    height: 15px;
    padding: 2px;
    font-size: 10px !important;
}  
.editnotesinput{
    display: flex !important;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}
.leads-sidebar .p-sidebar-icons{
     position: absolute;
right: 0;
top: 12px;
box-shadow: none !important;
}
.leads-sidebar .p-link{
    border-radius: 50px !important;
    box-shadow: none !important;
}
.leads-sidebar .p-sidebar-icons .pi{
    font-size: 0.8rem !important;
}
.leads-sidebar .p-sidebar-content{
    height: 100% !important;
}
.viewdetailsaddnotesbtn{
cursor: pointer;
font-size: 12px;
padding: 0.4rem 0.2rem;
background-color: #ED7D31;
color: white;
font-family: 'Calibri';
border: none;
}
.viewdetailsmap{
    border-radius: 4px;
    width: 100%;
    height: 25%;
    overflow: hidden;
}
.view-details-div::-webkit-scrollbar-thumb {
    background-color: #ED7D31;
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box; 
  }
  .view-details-div::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border: 4px solid transparent;
    border-radius: 100px;
    background-clip: padding-box; 
}
.view-details-div::-webkit-scrollbar{
    width: 10px !important;
  }
  .viewdetailsmapstyle{
    box-sizing: border-box;
    width: 100%;
    border-width: 0px;
  }
  .field-checkbox .p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover{
    background-color: #ED7D31 !important;
    border-color: #ED7D31 !important;
}
.p-checkbox .p-checkbox-box.p-highlight{
    background-color: #ED7D31;
}
.recbNotAvailableLable {
    font-size: 14px;
    font-family: 'Raleway Regular';
    font-weight: 600;
    justify-content: center;
    width: 100%;
    display: flex;
    padding: 30px 10px;
}