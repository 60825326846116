.search{
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
}
  .Rbac .panelContent {
    padding: 20px;
    height: 100%;
    position: relative;
  }
.rbprop{
    width: 100%;
    /* height: 90% !important; */
}
.MuiTypography-h4{
    font-size: 15px !important;
}
.MuiPickersBasePicker-pickerView {
    min-width: 100px !important;
    max-width: 300px !important;
    max-height: 310px !important;
    min-height: 150px !important;
}
.rbpropcard .overlaydatediv{
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid rgb(206, 212, 218);
    height: 29px;
    border-radius: 3px;
    padding-right: 5px;
}
.overlaydatediv .MuiInputBase-input {
    font-size: 11px !important;
    text-align: start !important;
    cursor: default !important;
    color: #898e93 !important;
    padding: 0px 3px;
}

.rbpropcard .filterCalenderBox {
    border-radius: 10px !important;
    margin-right: 10px !important;
    width: 100%;
    display: flex;
}
.rbpropcardContainer{
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 60%;
    margin-top: 10px;
    justify-content: flex-start;    
    
}
.rbprop .p-tabview .p-tabview-nav {
    border: none;
}
.rbprop .gridtab{
    width: 100%;
    height: 100%;
    font-family: "Raleway Regular";
}
.Rbac .panelContent {
    padding: 20px;
    height: 100%;
    background-color: #ffffff;
  }
.rbpropcard{
    flex:1 0 24%;
    /* height: 100%; */
    border-radius: 3px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
}
.rbprop .gridtab .p-tabview-panels{
    height: 90%;
    padding:0;
} 
.rbprop .p-tabview-panel{
    height: 100%;
    background-color: #F6F6F6;
}
.rbproptable{
    /* height: 40%; */
    margin-bottom: 10px;
    margin-top: 10px;
    gap: 10px;
    display: flex;
    width: 100%;
    border-radius: 3px;
    background-color: white;
    box-shadow: 0px 3px 6px #00000029;
}
.rbprop h5 {
    font-size: 13px;
    margin-top: 10px;
    padding-left:10px;
    margin-bottom: 10px;
    color: #ed7d31;
    font-family: 'Calibri';
  }
  .rbprop .dropdownPropertyBasic {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    margin-bottom: 10px;
}
.rbprop .pbdropdownContent {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-right: 10px;
    margin-bottom: 10px;
    /* grid-gap: 10px; */
    gap: 5px;
}
.rbprop .labelstyle {
    /* margin-bottom: 10px; */
    font-family: 'Raleway Regular';
    font-size: 11px;
    color:#ed7d31;
    display: flex;
    align-items: center;
}
.rbprop .p-multiselect, .rbprop .p-dropdown{
    height: 26px;
}

.rbprop .p-multiselect .p-multiselect-panel{
max-width: 100% !important;
}
.rbprop .p-multiselect .p-multiselect-label,.rbprop .p-dropdown .p-dropdown-label{
    font-size: 12px;
    height: 100%;
    display: flex;
    align-items: center;
    padding:0.5rem;
}
.rbprop .p-multiselect .p-multiselect-label.p-placeholder,.rbprop .p-dropdown .p-dropdown-label.p-placeholder{
    font-size: 11px !important;
    font-family: 'Raleway Regular';
}
.p-slider.p-slider-horizontal {
    height: 12px !important;
    width: 100% !important;
    background-color: lightgrey;
    margin: 7px 0px;
}
.p-slider .p-slider-range {
    background: #ed7d31 !important;
}
.p-slider .p-slider-handle{
background-color: #ed7d31 !important;
border-radius: 10px !important;
border: 5px solid #F6F6F6 !important;
}
.p-slider-handle:hover{
    background-color: #ed7d31 !important;
    border-radius: 10px !important;
    border: 5px solid #F6F6F6 !important;
} 
.reportlisttable{
text-align: left !important;
    font-family: 'Raleway Regular' !important;
    font-size: 12px !important;
    font-weight: normal !important;
    color: #44546a !important;
    padding: 0 !important;
}
.reportlisttable th{
    border-bottom: 1px solid #ed7d31 !important;
    font-family: 'Raleway Regular';
    font-size: 12px !important;  
}
.reportlisttable td{
    border: none !important;
    padding: 10px  1rem !important;
    font-family: 'Calibri';
    font-size: 12px !important;
}
.reportlisttable tr{
    border-bottom: 1px solid #f9d2b8 !important;
    border-top: none !important;
}
.reportlisttable  tr:last-child{
    border-bottom: none !important;
}
.reportlisttable .p-datatable-wrapper{
    border-radius: 3px;
}
.reportlisttable .p-datatable-tbody tr:hover{        
    background-color: #f3e2d7;
 }
 .reportlisttable .p-datatable-tbody tr{
   background-color: #fff;
   transition: background-color 1s ease-out;
}
.report-container {
    /* margin-top: 10px; */
    width: 100%;
    /* padding-left: 10px; */
    height: 100%;
}
.searchbtn{
    /* margin: 15px 0px 5px 0px; */
      height: 35px;
      background: #ed7d31;
      border-radius: 3px;
      border: none;
      font-family: 'Calibri';
      font-size: 14px;
      color: #ffffff;
      margin-right: 1px;
      cursor: pointer;
      min-width: 100px;
    }
    .totalrecord{
        font-family: 'Raleway Regular' !important;
        font-size: 12px !important;
        color: #44546a !important;
        display: flex;
        align-items: center !important;
        gap:5px !important
      }
      .totalrecordcount{
        font-family: 'Calibiri' !important;
        font-size: 12px !important;
      }
      .export{
        display: flex;
        justify-content: flex-end;
      }
      .exportcount{
        display: flex;
        justify-content: flex-end;
        margin: 10px;
      }
.loancheckbox{
    display: flex !important;
    gap: 10px !important;
    font-size: 12px !important;
}
.rbprop .p-radiobutton .p-radiobutton-box{
    width: 18px !important;
    height: 18px !important;
}
.loanbtn{
    display: flex !important;
    align-items: center !important;
    gap: 2px !important;
}
.rbprop .p-radiobutton .p-radiobutton-box.p-highlight {
    background-color: #ed7d31 !important;
    border-color: #ed7d31 !important;
} 
.rbprop .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 10px !important;
    height: 10px !important;
    background-color: white;
}
.slidertooltip > .p-tooltip-text{
        background-color: #ed7d31 !important;
        color: #fff !important;
        display: flex;
        height: 10px !important;
        font-size: 8px !important;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        padding: 8px 5px  !important;
    }
 .slidertooltip > .p-tooltip-arrow{
     display: none !important;
    }
    .nodata{
        display: flex;
        justify-content: center;
        font-family: "Raleway Regular";
    }
    .slidercontainer{
        display: flex !important;
        gap: 15px !important;
    }
    .sliderinput .p-inputtext{
        width: 50px !important;
        padding: 3px !important;
        text-align: center !important;
    }
    .addicon , .viewicon{
        position: relative;
        display: inline;
        color: #ed7d31 !important;
        font-size: 0.8rem !important;
        cursor: pointer !important;
    }
   .addbtn{
    text-align: center;
    /* margin: 15px 0px 5px 0px; */
      background: #ed7d31;
      border-radius: 3px;
      border: none;
      font-family: 'Calibri reg';
      font-size: 10px !important;
      color: #ffffff;
      /* margin-right: 1px; */
      cursor: pointer;
      min-width: 32px;
    }
    .rbprop .p-multiselect-panel .p-multiselect-items .p-multiselect-item{
        width: 100% !important;
        text-wrap: wrap !important;
        font-size: 12px;
    font-family: 'Raleway Regular';
    }
    .addviewlead{
        justify-content: center;
        display: flex;
        gap:5px !important;
    }
    .enternotes .p-inputtext {
        font-family: 'Raleway Regular' !important;
        width: 250px !important;
        font-size: 12px !important;
        padding: 0.5rem 0.75rem !important;
        text-align: left !important;
    }
      .rbprop .p-multiselect-panel .p-multiselect-header .p-checkbox{
        display: none !important;
      }
      .rbproptable .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon,.rbproptable .p-datatable .p-sortable-column.p-highlight{
        color: #ed7d31 !important;
      }
    
     .rbprop .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
        margin-top: -0.35rem !important;
        font-size: 0.7rem !important;
     }
     .rbprop .p-multiselect-panel .p-multiselect-header{
      gap:5px !important
     }
     .rbprop .p-multiselect-panel .p-multiselect-header .p-multiselect-close{
      margin-left: 0px !important;
      width: 15px !important; ;
      height: 12px !important;
     }
     .rbprop .clrfilter{
        color: #495057 !important;
        font-size: 0.7rem !important;
        cursor: pointer !important;
        width: 5% !important;
     }
     .rbprop .p-multiselect.p-multiselect-chip .p-multiselect-token{
        width: 40% !important;
        padding:0.1rem 0.25rem !important;
        
        }
        .rbprop .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label{
            padding:0.2rem !important;
            width: 100% !important;    
        }
        .rbprop .p-multiselect-token-label{
            width: 10px !important;
            width: 90% !important;
            overflow: hidden !important;
            display: block !important;
            white-space: nowrap !important;
            cursor: pointer !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
        }
        .rbprop .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon{
            margin-left: 0.20rem !important;
            font-size: 0.6rem !important;
            margin-top: 0.1rem !important;
        }
        .rbprop .p-multiselect-label-container{
            width: 100% !important;
        
        }
        .rbprop .p-multiselect .p-multiselect-trigger{
            width: 2rem !important;
        }
        .rbmultiselectheader{
            width: 85% !important;
    border: 1px solid #ced4da !important;   
    background: white !important;
    border-radius: 4px !important;
        }
        .rbheaderinput{
            width: 85% !important;
    border: none !important;
    border-radius: 4px !important;
    padding: 9px 12px !important;
        }
        .rbmultiselectheader:hover{
            border:1px solid #FBB700!important;
        }
        .rbheadertimes{
            position: relative !important;
    font-size: 0.7rem !important;
    padding: 0px 5px !important;
    cursor: pointer !important;
    top: -1px !important;
    /* right: 50px; */
    width: 5% !important;
    margin-right: 5px !important;
}
.propsearchcontainer{
display: flex;
flex-direction: column;
width: 100%;
gap:15px;
}
.propsearchswitch{
display: flex;
width: 100%;
gap: 20px;
padding-left: 20%;
}
.propsearchswitchstyle{
    height: 35px;
    border: 1px solid #ced4da;
font-size: 12px;
color: black;
font-weight: 600;
display: flex;
gap:20px;
padding: 0px 15px;
border-radius: 50px;
justify-content: center;
align-items: center;
font-family: 'Raleway Regular';
}
.propsearchswitchstylechecked{
    border: 1px solid #ED7D31;
    background-color: #fcf3ed;
    height: 35px;
font-size: 12px;
color: black;
font-weight: 600;
display: flex;
gap:20px;
padding: 0px 15px;
border-radius: 50px;
justify-content: center;
align-items: center;
font-family: 'Raleway Regular';
}
.propsearchinput{
    width: 100%;
    display: flex;
    padding: 5px;
    justify-content: center;
    /* width: 50%; */
}
.propsearchinput .p-inputgroup{
    width: 60% !important;
}
.propbtn {
    width: 10% !important;
    color: #fff !important;
    background: #ED7D31 0% 0% no-repeat padding-box !important;
    /* border: 1px solid #ED7D31 !important; */
    border-radius:0px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    opacity: 1 !important;
    cursor: pointer !important;
    font-size: 14px !important;
    font-weight: normal !important;
    justify-content: center !important;
}

.vdsidebarContainer{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:8px;
}
.Rbac .p-sidebar{
    position: fixed !important; /* Fix the sidebar to the viewport */
    top: 0 !important;
    left: 0 !important;
    padding: 0px 10px 10px 10px !important ;
    width: 100vw !important;
    max-height: 100vh !important;
    z-index: 999999 !important;
}
body.modal-open, html.modal-open {
    overflow: hidden; /* Prevent scrolling */
    height: 100%; /* Ensure full height */
    margin: 0; /* Remove default margins */
}


.vdheader{
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-family: 'Raleway Regular';
    font-weight: 600;
    height: 5%;
    padding: 12px 20px;
}
.vdContentContainer{
    display: flex;
    flex-direction: column;
    height: 95%;
    gap: 8px;
    background-color: #F6F6F6;
    /* background-color: #ED7D31; */
    padding: 10px;

}
.vdmapContainer{
    height: 50%;
    display: flex;
    gap:8px;
}
.vdmapdiv,.vddatadiv{
    width: 50%;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.vdpropmaptitle{
    width: 100%;
 display: flex;
 flex-direction: row !important;
 gap:10px;
}

.vddataContainer{
    height: 50%;
    display: flex;
    gap:8px;
}
.vdmaptitle,.vddatatitle{
    min-width: fit-content;
    font-size: 12px;
    font-family: 'Raleway Regular';
    font-weight: 600;
    height: 8%;
    text-transform: uppercase;
    margin-bottom: 3px;
}
.vdpropcount{
    min-width: fit-content;
    font-size: 12px;
    font-family: 'Raleway Regular';
    font-weight: 600;
    margin-bottom: 3px;
    margin-right: 20px;
}
.vdmap{
    height: 75%;
    border-radius: 5px;
}
.vdmapdetails{
    height: 12%;
    width: 100%;
    font-size: 11px;
    font-family: 'Calibri';
    font-weight: 600;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap:0 5px;
    justify-content: space-between;
}
.vdmapdetailsowner{
    height: 50% !important;
}
.vdmapdetailstyletitle{
    font-size: 11px;
    font-family: 'Calibri';
    font-weight: 400;
    color: grey;
}
.vdmapdetailstyle{
    display: flex;
    flex-direction: column;
    gap:3px;
    height: 50%;
}


.Rbac .viewdetailsmapstyle{
height: 100%;
width: 100%;
}
.Rbac .vdmapdetailstylecontent{
font-size: 11px;
}
.vdmapdetailstyleowner{
    display: flex;
    flex-direction: row;
    gap:3px;
    /* height: 50%; */
    align-items: flex-end;
}
.vdpropmaptitleowner{
    width: 100%;
 display: flex;
 flex-direction: column !important;
}
.Rbac .vdmapdetailstylecontentowner{
    font-size: 11px;
    }
    .vdmapdetailstyletitleowner{
        font-size: 11px;
        font-family: 'Calibri';
        font-weight: 400;
        color: grey;
    }
.vddemographicdetails{
    height: 92%;
    font-size: 12px;
    font-family: "Calibri";
    display: flex;
    flex-direction: column;
    gap:5px;
    align-items: center;
    justify-content: flex-start;
}
.vddemodiv{
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
font-weight: 400;
align-items: center;
}
.vddemostyle{
    display: flex;
    align-items: center;
}
.vddemostyle img{
    padding: 5px;
    height: 25px;
}
.vddownloadbtn {
    /* margin: 15px 0px 5px 0px; */
    /* height: 25px; */
    background: #ed7d31;
    border-radius: 3px;
    border: none;
    font-family: 'Calibri';
    font-size: 14px;
    color: #ffffff;
    margin-right: 50px;
    margin-top: -10px;
    cursor: pointer;
    min-width: 100px;
    padding: 5px;
}
.Rbac .leads-sidebar .p-sidebar-icons .pi {
    font-size: 0.7rem !important;
}
.Rbac .leads-sidebar .p-sidebar-icons {
    position: absolute;
    right: 20px;
    top: 10px;
    border: 1px solid #ced4da;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    z-index: 9999;
}
.fieldsetdh{
    /* border: 1px solid #ced4da; */
    border: none;
    padding: 0px !important;
}
.vd4cardsContainer{
   height: 45%;
   display: flex;
   flex-direction: column;
   gap: 10px;
}
.vd4cards{
    display: flex;
    gap: 10px;
    justify-content: space-between;
    height: 100%;
    overflow-y: hidden;
}
.vd4cardsstyle{
    border: 1px solid #ced4da;
    width: 33%;
    border-radius: 5px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}
.vd4cardsdiv{
font-family: 'Calibri';
display: flex;
flex-direction: column;
gap:5px
}
.vd4cardstitle{
font-size: 11px;
color: #495057;
}
.vd4cardsvalue{
font-weight: 900
}
.vd4cardsfooter{
font-size: 11px;
color: #ed7d31;
}
.vdchart{
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.dhimage{
    border: 1px solid #ced4da;
    border-radius: 5px;
    margin-right: 10px;
}
.demoloandiv{
    display: flex !important;
    flex-direction: row !important;
    background-color: #F6F6F6 !important;
    padding: 0px !important;
    /* width: 100%; */
}
 .demodiv{
    background-color: #fff !important;
    padding: 10px 15px !important;
    border-radius: 5px !important;
    flex: 1;
    /* max-width: 100%; */
    transition: flex-basis 0.3s;
}
.loandiv{
/* width: 35%; */
/* flex: 1; */
flex: 0 0 auto; /* Do not grow or shrink */
background-color: #fff !important;
padding: 10px 15px !important;
border-radius: 5px !important;
}
.container-with-second-div .demodiv{
    flex-basis: 65%; /* Width of first div when second div is present */
  }
  
  .container-without-second-div .demodiv {
    flex-basis: 100%; /* Full width of first div when second div is not present */
  }
.vdloandiv{
    display: flex;
    flex-direction: column !important;
    width: 100%;
    height: 24%;
    max-height: 60px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 12px;
    justify-content: space-evenly;
}
.vdloandetails{
    height: 92%;
    font-size: 12px;
    font-family: "Calibri";
    display: flex;
    flex-direction: column;
    gap:15px;
    align-items: flex-start;
    justify-content: flex-start;
}
.vdloanstyle{
    font-weight: 400;
    font-size: 12px;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
    font-size: 9px !important;
    margin-left: 0 !important ;
   }
   /* .datahubowner .p-multiselect-items-wrapper{
    max-height: 120px !important;
   } */
    .reportlisttable .p-datatable-scrollable-body>table>.p-datatable-tbody>tr>td:first-child{
        overflow: visible !important;
    }
   /* .reportlisttable .p-datatable-scrollable-body>table>.p-datatable-tbody>tr:first-child>td {
    overflow: visible !important;
} */
@media only screen and (min-width: 200px) and  (max-width: 600px)  {
    .propsearchinput .p-inputgroup {
        width: 100% !important;
    }
    .propsearchswitch{
        display: flex;
        flex-direction: column !important;
        padding-left: 0px;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    }
    .propsearchswitchstyle{
        width: 70% !important;
        justify-content: space-between;
    }
    .propsearchswitchstylechecked{
        width: 70% !important;
        justify-content: space-between;
    }
}

@media only screen and (min-width: 600px) and  (max-width: 820px){
    .propsearchinput .p-inputgroup {
        width: 100% !important;
    }
    .propsearchswitch{
        display: flex;
        flex-direction: row;
        padding-left: 0px;
        justify-content: center;
        align-items: center;
        width: 80% !important;
    }
    .propsearchswitchstyle{
        width: 100%;
        justify-content: space-between;
        gap: 10px;
    }
    .propsearchswitchstylechecked{
        width: 100%;
        gap: 10px;
        justify-content: space-between;
    }
}
@media (max-width: 1024px) {
    .rbpropcard{
        flex-basis: calc(50% - 20px);
    }
}

@media (max-width: 768px) {
    .rbpropcard {
        flex-basis: calc(50% - 20px);
    }
}
@media (max-width: 450px) {
    .rbpropcard {
        flex-basis: calc(100% - 20px);
    }
}


