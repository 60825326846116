body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f8fc;
  /* height: 100%; */
}
@font-face {
  font-family: "Raleway Regular";
  src: local("Raleway Regular"),
    url("../src/fonts/Raleway-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "Raleway Italic";
  src: local("Raleway Italic"),
    url("../src/fonts/Raleway-Italic-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "Calibri bo it";
  src: local("Calibri bo it"),
    url("../src/fonts/calibri-bold-italic.ttf") format("truetype");
}
@font-face {
  font-family: "Calibri bo";
  src: local("Calibri bo"),
    url("../src/fonts/calibri-bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Calibri it";
  src: local("Calibri it"),
    url("../src/fonts/calibri-italic.ttf") format("truetype");
}
@font-face {
  font-family: "Calibri reg";
  src: local("Calibri reg"),
    url("../src/fonts/calibri-regular.ttf") format("truetype");
  font-weight: 400;
}
.p-inputtext:enabled:focus,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none !important;
  border-color: #ed7d31 !important;
}
