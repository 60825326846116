 /* table */
 .p-datatable .p-sortable-column .p-sortable-column-icon {
    font-size: 9px !important;
    margin-left: 0 !important ;
   }
 .p-datatable-tbody tr:hover{     
    background-color: #f3e2d7;
  }
  .userlisttable .p-datatable-scrollable-body{
    height: 100% !important;
    max-height: 70vh !important;
    overflow-y: auto !important; 
    box-sizing: border-box;
  }
  .userlisttablestyle{
    text-align: left !important;
    font-family: 'Calibri';
    font-size: 12px;
    font-weight: normal;
    color: #44546a;
  }
  .p-datatable-tbody tr{
    background-color: #fff;
    transition: background-color 0.3s ease-out;
}
.userlisttable .p-datatable-scrollable-body::-webkit-scrollbar {
  width: 5px; /* Thinner scrollbar */
}

.userlisttable .p-datatable-scrollable-body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
}
.userlisttable th {
  text-align: left !important;
  font-family: 'Raleway Regular' !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #44546a !important;
  border: none !important;
  margin-bottom: 20px !important;
  position: sticky !important;
  top: 0 !important;
  padding: 5px !important;

}
.userlisttable .p-datatable-scrollable-header-box{
    margin-right: 0px !important;
}
.ellipsis-text {
    width: 100% !important;  /* Example width */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }
  .addicon .tooltiptext ,.viewicon .tooltiptext {
    color: #fff;
    font-family: 'Raleway Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    visibility: hidden;
    /* width: 150px; */
    text-align:center;
    padding: 2px 0px;
    border-radius: 6px;
    background-color: rgb(184, 182, 182);
    /* background-color: aqua; */
    text-shadow: 30px  rgb(214, 214, 214) ;
    border: 0.2px solid rgb(214, 214, 214);
    
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    width: 70px;
    z-index: 1 !important;
    bottom: 70%;
    left: 50%;
    margin-left: -17px;
    
  
  }
 .addicon:hover .tooltiptext ,.viewicon:hover .tooltiptext{
    visibility: visible;  
  }
  .addicon .tooltiptext::after ,.viewicon .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 25%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(184, 182, 182)  transparent transparent transparent;
  }
/* Responsive adjustments */
@media (max-width: 768px) {
  .table-container .p-datatable-wrapper {
    overflow-x: auto;
  }
   .p-datatable-thead > tr > th {
    white-space: nowrap;
  }
  .p-datatable-tbody td {
    white-space: nowrap;
  }
}
