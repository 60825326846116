.user{
    height: 100%;
    width: 100%;
    display: flex;
    gap:10px;
    padding: 5px;
    background-color:#f6f6f6;
  }
  .list-container{
   width: 100%;
   height: 100%;
   box-shadow: 0px 3px 6px #00000029;
  }
  .addorsubscribe{
    width: 50%;
    height: 70vh;
    background-color: white !important;
    box-shadow: 0px 3px 6px #00000029;
  }
 .dprs .p-dropdown-items-wrapper{
  max-height: 20vh !important;
 }
.userlisttable .activestatus{
  width: 10px;
  height: 10px;
  color: green;
  background-color: green !important;
  border-radius: 10px;

}
.userlisttable .activediv{
  display: flex !important;
  align-items:center !important ;
  justify-content: center !important;
}
.userlisttable .actionstyle{
  cursor: pointer;
  color:#ED7D31;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border-radius:5px;
  padding: 2px 5px;
 }
 .userlisttable .deleteicon, .userlisttable .editicon{
   font-weight: bolder !important;
   position: relative;
   display: inline;
   color: #ed7d31 !important;
   font-size: 0.8rem !important;
   cursor: pointer !important;
  }
.list-container .p-datatable .p-datatable-tbody > tr > td{
  padding: 8px !important;
  }
 .addsubbtndiv{
        padding: 5px;
        display: flex;
        justify-content: flex-end;
      }
      .addsubbtniac{
        border: 1px solid #ced4da;
    font-size: 12px;
    color: black;
    font-weight: 600;
    display: flex;
    gap:10px;
    padding:5px 8px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    font-family: 'Raleway Regular';
    }
    .addsubbtnac{
        border: 1px solid #ED7D31;
        background-color: #fcf3ed;
    font-size: 12px;
    color: black;
    font-weight: 600;
    display: flex;
    gap:10px;
    padding:5px 8px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    font-family: 'Raleway Regular';
    }
      .addsubbtn{
        height: 30px;
  background: #ed7d31;
  border-radius: 3px;
  border: none;
  font-family: 'Calibri';
  font-size: 14px;
  color: #ffffff;
  margin-right: 1px;
  cursor: pointer;
  min-width: 90px;
      }
      .adduserbtndiv {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: -20px;
        right: 12%;
        margin-right: 10px;
        z-index: 2;
    }
      .addorsubscribeform  .p-multiselect{
      height: 100% !important;
      min-width: 100% !important;
      width: 100% !important;
      }
      .addorsubscribeform  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
        font-size: 12px;
        font-family: 'Calibri';
        width: 100% !important;
        text-wrap: wrap !important;
    }
    .addorsubscribeform .p-checkbox .p-checkbox-box.p-highlight, .p-multiselect.p-multiselect-chip .p-multiselect-token {
      background: #ed7d31;
      border: 1px solid #ED7D31;
  }
  .addorsubscribeform .p-checkbox .p-checkbox-box .p-checkbox-icon{
    color: white;
  }
.addorsubscribeform h5 {
        font-size: 14px;
        margin-top: 0px;
        padding-left:10px;
      }    
.addorsubscribeform .dropdownPropertyBasic {
display: flex;
padding-left: 10px;
margin-bottom: 10px;
}
.addorsubscribeform .dropdownContent {
display: flex;
flex-direction: column;
width:40%;
margin-right: 40px;
gap:10px;
}
.addorsubscribeform .labelstyle {
font-family: 'Raleway Regular';
font-size: 11px;
color:#ed7d31;
}
.dropdownContentRoleState{
display: flex;
flex-direction: row;
width:40%;
margin-right: 40px;
gap:10px;
}
.dprs{
display: flex;
flex-direction: column;
width: 50%;
gap:10px;
}
.title {
margin-bottom: 0;
color: var(--clr-grey-1);
letter-spacing: 2px;
transition: var(--transition);
}
.title-center {
color: var(--clr-grey-1);
letter-spacing: 2px;
transition: var(--transition);
display: flex;
justify-content: center;
margin-top: 10rem;
}
.addorsubscribeform  .p-dropdown-panel .p-dropdown-items .p-dropdown-item{
font-size: 12px !important;
font-family: "Raleway Regular" !important;
}
.addorsubscribeform .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight  {
color: #ed7d31;
background: #f3e2d7 !important;
}
.btndiv{
display: flex;
padding-right: 10px !important;
justify-content: flex-end;
}
.subscriptionSelection{
box-shadow: 0px 3px 6px #00000029;
width: 100%;
height: 45vh;
border-top-left-radius: 30%;
border-bottom-right-radius: 30%;
overflow: hidden;
}
.ssheader{
background-color: #ed7d31;
padding: 10px;
color: white;
font-family: "Raleway Regular";
display: flex;
justify-content: center;
padding-left: 20px;
align-items: center;
font-weight: 600;
height: 15%;
}
.sscontent{
padding: 10px;
overflow-y: auto;
height: 75% !important ;
}
.ssfooter{
background-color: #ed7d31;
display: flex;
justify-content: center;
color: white;
font-size: 12px;
font-family: 'Raleway Regular';
height: 15%;
align-items: center;
padding-bottom: 15px;
}
.css-15k4e8y-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-15k4e8y-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
color: #ED7D31 !important;
}
.addorsubscribeform .proceedbtn {
display: flex;
width:100px;
justify-content: center;
align-items: center;
padding: 5px;
background-color: #f3e2d7;
color:#ED7D31;
border: none;
border-radius: 20px;
}
.addorsubscribeform .proceedbtn:hover {
cursor: pointer;
}
.addorsubscribeform .proceedbtn:hover .parrow{
margin-left: 10px;
transition: margin 500ms;
}
.parrow{
font-size: 12px;
margin-left: 5px;
}
.proceeddiv{
width: 100%;
padding: 10px;
display: flex;
justify-content: flex-end;
}
.addorsubscribe .p-radiobutton .p-radiobutton-box.p-highlight {
background-color: #ed7d31 !important;
border-color: #ed7d31 !important;
}
.addorsubscribe .p-radiobutton .p-radiobutton-box {
width: 15px !important;
height: 15px !important;
}
.addorsubscribe .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
width: 9px;
height: 9px;
background-color: white;
}
.addorsubscribe .p-radiobutton{
height: 100%;
display: flex;
justify-content: center;
align-items: center;
}
.addsubcheck{
display: flex;
gap:10px
}
.editicon .tooltiptext ,.deleteicon .tooltiptext {
color: #fff;
font-family: 'Raleway Regular';
font-style: normal;
font-weight: 400;
font-size: 9px;
visibility: hidden;
text-align:center;
padding: 2px 0px;
border-radius: 6px;
background-color: rgb(184, 182, 182);
text-shadow: 30px  rgb(214, 214, 214) ;
border: 0.2px solid rgb(214, 214, 214);
position: absolute;
width: 70px;
z-index: 1 !important;
bottom: 70%;
left: 50%;
margin-left: -27px;
}
.editicon:hover .tooltiptext ,.deleteicon:hover .tooltiptext{
visibility: visible;
}
.editicon .tooltiptext::after ,.deleteicon .tooltiptext::after {
content: " ";
position: absolute;
top: 100%;
left: 50%;
margin-left: -12px;
border-width: 5px;
border-style: solid;
border-color: rgb(184, 182, 182)  transparent transparent transparent;
}
.subscriptionForm{
height: 100%;
width: 100%;
display: flex;
flex-wrap: wrap;
gap:10px;
}
.sfleft{
flex: 1 0 200px;
height: 100%;
}
.sfright{
flex:3 0 200px;
height: 100%;
}
.sfleft .p-field-radiobutton > label {
font-size: 12px;
font-family: 'Raleway Regular';
margin-left: 0.5rem;
line-height: 1;
}
.addorgbtn{
height:31px;
background: #ed7d31;
border-radius: 3px;
border: none;
font-family: 'Calibri';
font-size: 14px;
color: #ffffff;
cursor: pointer;
width: 20%;
}
.panelDiv{
width: 100%;
height: 100%;
display: flex;
gap:10px;
flex-wrap: wrap;
}
.rbactableleft{
height: 100%;
flex: 1 0 300px;
}
.rbacaddright{
flex: 4 0 300px;
height: 100%;
}
.subfirstdiv{
display: flex;
/* flex-wrap: wrap;
flex: 1 0 50px; */
;
}
.subfirstdiv .dropdownContent{
flex:1;
}
.subfirstdiv .dropdownContent:nth-child(1),.subfirstdiv .dropdownContent:nth-child(2){
flex-basis: 40%;
}
.subfirstdiv .dropdownContent:nth-child(3){
flex-basis: 50%;
}
.backtoaddbtn{
border-radius: 3px;
border: none;
font-family: 'Calibri';
font-size: 14px;
color: #ED7D31;
margin-left: 20px;
cursor: pointer;
min-width: 40px;
}
.mainContainer{
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
}
.selectionContainer{
width: 100%;
display: flex;
flex: 2 0 300px;
gap:10px;
height: 100%;
max-height: 100%;
flex-wrap: wrap;
justify-content: flex-end;
}
.chipContainer{
max-height: 100%!important;
flex: 1 0 150px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-end;
gap:10px;
max-width: 350px;
}
.chipContainer .p-chips{
height: auto;
margin: 10px;
width: 100%;
}
.chipContainer .p-chips .p-chips-multiple-container .p-chips-token{
margin-right: 0px;
margin-bottom: 2px;
width: 100%;
display: flex;
justify-content: space-between;
background-color: #f3e2d7;
}
.chipContainer .p-inputtext:focus {
outline: none !important;
box-shadow: none !important;
border: none !important;
}
.chipContainer .p-chips-input-token input{
display: none !important;
}
.msa-selector {
  min-width: 300px;
  max-width: 350px;
flex: 2 0 150px;
padding: 10px;
background-color:#ED7D31;
border-radius: 10px;
color: white;
font-family: "Calibri";
}
.msa-selector h3 {
margin-top: 0;
}
.msa-selector .p-listbox .p-listbox-item {
display: flex;
justify-content: space-between;
padding: 0.5rem;
border-bottom: 1px solid #ddd;
cursor: pointer;
font-size: 12px;
font-family: 'Raleway Regular';
font-weight: 600;
}
.msa-selector .p-listbox .p-listbox-item:last-child {
border-bottom: none;
}
.msa-selector .p-listbox .p-listbox-item .pi {
font-size: 12px;
color: #ED7D31;
}
.msa-selector .p-jc-between {
justify-content: space-between;
display: flex !important;
align-items: center !important;
flex: auto !important;
}
.chipContainer .p-chips .p-chips-multiple-container {
display: flex;
flex-wrap: wrap;
padding: 0px !important;
width: 100%;
max-height: 250px;
overflow-y: auto !important;
border: none !important;
gap:5px
}
.msa-selector .p-chips-token {
background-color: #fff;
color: #000;
margin: 0.2rem;
padding: 0.2rem 0.5rem;
border-radius: 1rem;
display: flex;
align-items: center;
}
.msa-selector .p-chips-token .pi-minus {
cursor: pointer;
margin-left: 0.5rem;
}
.msa-selector .p-inputtext {
width: 100%;
padding: 0.5rem;
border-radius: 5px;
border: none;
}
.msa-selector .custom-chips .p-chips-input-token {
display: none;
}
.selected-info, .limit-info {
margin-top: 1rem;
text-align: center;
font-weight: bold;
}
.proceedContainer{
display: flex;
justify-content: center;
align-items: flex-end;
margin-top: 20px;
}
.AccessControl .panelContent{
height: 100%;
}
@media (max-width: 1024px) {
  .sfleft,.sfright{
    flex-basis: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .sfleft,.sfright{
    flex-basis: calc(50%);
  }
  .msa-selector,.chipContainer{
    flex-basis: calc(20%);
  }
  .subfirstdiv{
    display: flex;
   flex-wrap: wrap;
  }
  .subfirstdiv .dropdownContent{
    flex-basis: calc(50%);
    }
}
@media (max-width: 500px) {
  .adduserbtndiv {
    display: flex;
    justify-content:flex-end;
    position: absolute;
    z-index: 2;
    right: 30%;
    top:-40px
  }
  .orgicon {
    position: absolute;
    z-index: 1;
    right: 2%;
    top: 45px;
    width: 25%;
    min-height: 30px !important;
    cursor: pointer;
}
.sfleft,.sfright{
  flex-basis: calc(100%);
}
.msa-selector,.chipContainer{
  flex-basis: calc(100%);
}
.AccessControl .panelContent {
 margin-top: 50px !important;
}
  }