.sideheader{
    height: 100% !important;
}

.view-details-div 
.view-details-style{
    line-height: 10px !important;
    font-size: 12px !important;
    font-family: 'Calibri' !important;
}
.view-details-style p {
    width: 100% !important;
    color: #303030 !important;
}
.view-details-style span{
    max-width: 70%;
    display: flex;
    justify-content: right;
    font-weight: 600;
    float: right;
    text-align: end;
    align-items: flex-end;
}
.view-details-div{
    height: 100% !important;
    overflow-y: auto !important;
    padding-right: 10px;

}
.dealfooter{
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    gap: 20px;
}
.dealbtn{
    width: 100px !important;
    height: 38px !important;
    color: #fff !important;
    /* width: 45%; */
    background: #ED7D31 0% 0% no-repeat padding-box !important;
    border: 1px solid #ED7D31 !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    cursor: pointer !important;
    font-size: 14px !important;
    font-weight: normal !important;
    justify-content: center !important;
}
.addbtn{
    width: 50px !important;
    height: 20px !important;
    color: #fff !important;
    /* width: 45%; */
    background: #ED7D31 0% 0% no-repeat padding-box !important;
    border: 1px solid #ED7D31 !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    cursor: pointer !important;
    font-size: 12px !important;
    font-weight: normal !important;
    justify-content: center !important;
    align-self: center !important;
}
.addchangestyle{
    font-family: 'Raleway Regular';
    font-size: 14px;
    font-weight: 600;
}
.enternotes
{
display: flex;
justify-content: center;
margin-bottom: 10px;
}
.enternotes .p-inputtext{
    font-family: 'Raleway Regular' !important;
    width: 250px !important;
    font-size: 14px !important;
}


.expandtable .p-datatable-tbody > tr > td:nth-child(3) {
    color:#ED7D31 !important;

}
.alignnotes{
    display: flex;
    flex-direction: column;
align-items: center;

}

.rbbuyerstb td:nth-child(2),.rbbuyerstb th:nth-child(2){
    width: 100px;
    text-align: center !important;
 }
 .leadsaddnotesbtn{
    display: flex !important;
    color:#fff !important;
    /* width: 45%; */
    background: #ED7D31 0% 0% no-repeat padding-box !important;
    border: 1px solid #ED7D31 !important;
    border-radius: 4px !important;
    opacity: 1 !important;
    cursor: pointer !important;
    font-size: 12px !important;
    font-style: "Raleway Regular";
    font-weight: normal !important;
}
.leadbtndiv{
    display: flex !important;
    justify-content: flex-end !important;
    padding: 2px 0;
}
.leadstatuslabel{
    font-family: 'Raleway Regular';
    font-size: 11px;
    font-weight: 400;
    color: black;
    padding: 0 0.2rem;
}
.view-details-div .p-accordion-header-text {
    line-height: 1;
    font-size: 13px;
    /* color: #F98436; */
    color: #ED7D31;
    font-weight: 600;
    font-family: 'Calibri' !important;
}

.view-details-div .p-accordion .p-accordion-content{
    border: none !important;
    padding: 0 !important;
}
.view-details-div .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon{
    color: #ED7D31 !important;
    font-size: 0.7rem !important;
    margin-top: -8px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-right: 0px !important;
}
.view-details-div .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link ,
.view-details-div .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link,
.view-details-div .p-accordion .p-accordion-header .p-accordion-header-link,
.view-details-div .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    border: none;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
    background-color: white; 
}

.view-details-div .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none !important;
    border: none;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
    background-color: white; 

}
.view-details-div .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 0 !important;
    margin-top: 12px !important;
}
.view-details-div .notesdiv{
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    padding: 1rem 0;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
    margin-top: 12px;
}


.view-details-div .addnotesicon{
    display: flex;
    gap:5px;
    width: 5%;
}
.view-details-div .notesicon{
    color: white !important;
    font-size: 0.7rem !important;
    cursor: pointer;
}
 .addnotesinput .p-inputtext {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    width: 100% !important;
}
.view-details-div .addnotesinput{
   width: 85%;
}

.view-details-div .addnotesdiv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* padding: 1rem 0; */
    margin-top: 15px;
    border-radius: 4px !important;
    gap:7px;
    width: 100%;

    background-color: #ED7D31;
}
.view-details-div .viewnotesdiv{
    width: 100%;
    height: 30vh;
    margin-top: 10px;
    border-radius: 4px !important;
    overflow-y: auto;

}
.view-details-div .p-datatable-wrapper{
    height: 100% !important;
    overflow-x: auto;
}
.viewnotesdiv th{
position: sticky !important;
top: 0 !important;
border-style: none !important;
z-index: 1 !important;
}
.viewnotesdiv .p-column-title,.viewnotesdiv  td{
    font-family: "Calibri" !important;
    font-size: 12px !important;
}
.view-details-div th:nth-child(2) {
    width: 100px;
    text-align: center !important;
}
.view-details-div .p-datatable .p-datatable-tbody > tr > td{
    text-align: left !important;
    padding: 0.3rem !important;
}
.view-details-div .p-datatable .p-datatable-thead > tr > th {
    text-align: left !important;
    padding: 0.5rem !important;
}
.editnotesinput .p-inputtext{
    width: 90% !important;
    height: 15px;
    padding: 2px;
    font-size: 10px !important;
}  
.editnotesinput{
    display: flex !important;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}
.leads-sidebar .p-sidebar-icons
{
     position: absolute;
right: 0;
top: 12px;
box-shadow: none !important;

}

.leads-sidebar .p-link{
    border-radius: 50px !important;
    box-shadow: none !important;
}
.leads-sidebar .p-sidebar-icons .pi{
    font-size: 0.8rem !important;
}
.leads-sidebar .p-sidebar-content{
    height: 100% !important;
}
.viewdetailsaddnotesbtn{
cursor: pointer;
font-size: 12px;
padding: 0.4rem 0.2rem;
background-color: #ED7D31;
color: white;
font-family: 'Calibri';
border: none;
}
.viewdetailsmap{
    border-radius: 4px;
    width: 100%;
    height: 25%;
    overflow: hidden;
}

.view-details-div::-webkit-scrollbar-thumb {
    background-color: #ED7D31;
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box; 
   
    
    
  }
  .view-details-div::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border: 4px solid transparent;
    border-radius: 100px;
    background-clip: padding-box; 

}
.view-details-div::-webkit-scrollbar{
    width: 10px !important;
  }
  .viewdetailsmapstyle{
    box-sizing: border-box;
    width: 100%;
    border-width: 0px;

  }