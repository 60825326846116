.idle-timeout {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
  
  .idle-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .idle-content {
    text-align: center;
    color: white;
  }
  
  .idle h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .idle p {
    font-size: 16px;
  }
    