.pagedownloadbtn{
    height: 30px;
    position: absolute;
    z-index: 9999;
    right: 180px;
    top:20px;
    cursor: pointer;
}
/* download.css */
@media print {
    .landscape {
        position: absolute !important;
        width: 100vw;
    }

    .printable {
        position:fixed;
        margin-top: 20px;
        top: 0;
        left: 0;
        width: 100vw;
        height:100%  !important;
        background: white;
        z-index: 9999;
        padding: 0;
        overflow: hidden;
        box-sizing: border-box;
    }
}

.hidden {
    display: none;
}