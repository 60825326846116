.mioverviewAnalysis{
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    /* box-shadow: 0px 8px 20px #00000026; */
    /* border-radius: 10px; */
    display: flex;
    justify-content: space-between;
    height: 21%;
    margin: 3px;
}

.mioverviewMain{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  justify-content: space-between;
  /* width: 49.5%; */
  -webkit-box-shadow: 0px 0px 2px 0px #00000029;
  -moz-box-shadow: 0px 0px 2px 0px #00000029;
  box-shadow: 0px 0px 2px 0px #00000029;
  height: calc(92%);
  width: 16%;
  /* height: calc(50%); */
  overflow: hidden;
  /* margin-top: 2px; */
  margin-bottom: 0px;
}
/* .mioverviewMain:nth-child(1),
  .mioverviewMain:nth-child(2){
    margin-bottom: 2px;
    margin-top: 0px;
   
} */
.mioverviewContent{
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: center;
  
}
.mioverviewContent p{
  align-self: flex-end;
  margin: 0px;
  display: flex;
  justify-content: right;
  padding-right: 5px;
}
.mioverviewImageDiv{
  width:  30px !important;
  height:  30px !important;
  /* padding: 2%; */
  }
.mioverviewImage{
max-width: 30px;
max-height: 30px;
padding: 10% !important;
}
.mioverviewHeader{
    color: #44546a;
    font-family: 'Calibri';
font-style: normal;
font-weight: 600;
font-size: 11px;

}

.mioverviewSubHeader{
    color: #44546a;
    font-family: 'Calibri';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
}

.mioverviewChart{
 width:100%;
 margin-bottom: 10px;
 height: 75%;
}
.info{
  position: relative;
display: inline;
/* color: darkblue; */
}

.info .tooltiptext {
  color: #fff;
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  visibility: hidden;
  /* width: 150px; */
  text-align:left;
  padding: 5px;
  border-radius: 6px;
  background-color: rgb(184, 182, 182);
  /* background-color: aqua; */
  text-shadow: 30px  rgb(214, 214, 214) ;
  border: 0.2px solid rgb(214, 214, 214);
  
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 99;
  top: -5px;
  left: 100%;
  /* margin-left: -120px; */
}

.info:hover .tooltiptext {
  visibility: visible;

}
.info .tooltiptext::before {
  content: " ";
  position: absolute;
  top: 40%;
  right: 100%; /* Position the arrow to the left of the tooltip */
  margin-top: -5px; /* Center the arrow vertically */
  border-width: 5px;
  border-style: solid;
  border-color: transparent rgb(184, 182, 182) transparent transparent; /* Arrow color */
  z-index: 2000 !important;
}

.headercontent{
  display: flex;
  align-self: flex-end;
  margin: 0px;
  gap: 2px;
  justify-content: right;

}
.overviewContainer{
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* height: 60%; */
  justify-content: space-around;    
  
}
.mioverviewMain{
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  max-height: calc(18vh);
  height: 100% !important;
  flex:1 0 16%;
}
@media (max-width: 1024px) {
  .mioverviewMain{
      flex-basis: calc(33.33% - 20px);
      margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .mioverviewMain {
      flex-basis: calc(50% - 20px);
      margin-bottom: 10px;

  }
}
@media (max-width: 450px) {
  .mioverviewMain {
      flex-basis: calc(100% - 20px);
      margin-bottom: 10px;

  }
}