:root {
  --font-family: "Noto Sans", sans-serif;
  --global-heading-font-family: "Barlow Semi Condensed", sans-serif;
  --font-color-primary: #324158;
  --font-color-orange: #ff7e25;
  --font-color-black: #222222;
  --font-color-white: #ffffff;
  --font-color-gray: #777777;
  --background-primary: #ffffff;
  --background-color-two: #f7f7f7;
  --background-color-orange: #ed7d31;
  --background-color-grey: #eeeeee;
}
.sub-highlight {
border: 2px solid rgb(237,125,49) !important;
}

.cus-flex {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.sub_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 0%;
  padding: 5px 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  max-height: fit-content;
  font-family: var(--font-family);
}
 .footer-style{
  display: flex;
   flex-direction: row;
}
.sub_btn:hover,
.sub_active {
  background-color: var(--font-color-primary) !important;
  border-radius: 8px !important;
  color: #fff !important;
  font-family: var(--global-heading-font-family) !important;
}
.sub_btn {
  border-radius: 8px !important;
  font-family: var(--global-heading-font-family) !important;
}
.subs {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80%;
  min-height: 100vh;
  justify-content: space-between;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.subscribeNowPage {
  margin: 20px 0px;
  display: flex;
  flex: 10 1 0%;
  gap: 20px;
  flex-direction: column;
  justify-content: space-around;
  color: var(--font-color-black);
  background-color: var(--background-primary);
  font-family: var(--font-family);
}
.subscribeNowPage h4 {
  font-size: 2.7rem;
  text-align: center;
  font-weight: 400;
  flex: 1 1 0%;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--global-heading-font-family);
}
.subscribeNowPage .custom-switch {
  display: flex;
  justify-content: center;
  flex: 3 1 0%;
}
.chooseSubMainContainer {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex: 8 1 0%;
  gap: 8px;
}
.subcard {
  border-radius: 16px;
  height: 100%;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #e2e2e2;
  background-color: var(--background-primary);
}
.subcard .p-card-header {
  flex: 2 1 0%;
  /* background: rgb(237, 232, 232); */
  color: #44546a !important;
  font-family: var(--global-heading-font-family);
  font-size: 13px;
  display: flex;
  flex-direction: column;
}
.subscribeNowPage .p-card .p-card-body {
  flex: 4 1 0%;
  /* background: green; */
}
.fillSubMainContainer {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  height: 100%;
}
.panelContentPaymentAddSub {
  flex: 2 1 0%;
  display: flex;
}
.paymentLoaderDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addorsubscribepaymentform {
  border: none;
  border-radius: 20px;
  display: flex;
  margin: 5px 10px;
  flex: 1 1 100%;
}
.spform {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}
.spleft {
  flex: 1 1 35%;
  margin: 5px 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 2px 8px;
  border-radius: 10px;
}
.spright {
  flex: 2 1 50%;
  margin: 5px 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 2px 8px;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}
.msaSelectionMainContainer {
  justify-content: left;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.subselectionContainer {
  display: flex;
  gap: 10px;
}
.subchipContainer {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 10px;
}
.subchipContainer .p-chips {
  height: auto;
  margin: 10px;
  width: 100%;
}
.subchipContainer .p-chips .p-chips-multiple-container .p-chips-token {
  margin-right: 0px;
  font-size: 12px;
  margin-bottom: 2px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #f3e2d7;
  border-radius: 0px;
  font-family: var(--font-family);
}
.subchipContainer .p-inputtext:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.subchipContainer .p-chips-input-token input {
  display: none !important;
}

.sub-msa-selector {
  flex: 1 1 0%;
  padding: 10px;
  background-color: #ed7d31;
  border-radius: 10px;
  color: white;
  font-family: var(--font-family);
}
.sub-msa-selector h3 {
  margin-top: 0;
  font-size: 18px;
}

.sub-msa-selector .p-listbox .p-listbox-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  font-family: var(--font-family);
}
.sub-msa-selector .p-listbox .p-listbox-item:last-child {
  border-bottom: none;
}
.sub-msa-selector .p-listbox .p-listbox-item .pi {
  font-size: 12px;
  color: #ed7d31;
}
.sub-msa-selector .p-jc-between {
  justify-content: space-between;
  display: flex !important;
  align-items: center !important;
  flex: auto !important;
}
.subchipContainer .p-chips .p-chips-multiple-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0px !important;
  width: 100%;
  max-height: 250px;
  overflow-y: auto !important;
  border: none !important;
  gap: 5px;
}
.sub-msa-selector .p-chips-token {
  background-color: #fff;
  color: #000;
  margin: 0.2rem;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
}
.sub-msa-selector .p-chips-token .pi-minus {
  cursor: pointer;
  margin-left: 0.5rem;
}
.sub-msa-selector .p-inputtext {
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  border: none;
}
.subscribeNowPage .p-inputtext {
  font-family: var(--font-family);
}
.chips-container {
  background-color: #ffffff;
  min-height: 235px;
  max-height: 235px;
}
.sub .sub-msa-selector .sub-custom-chips .p-chips-input-token {
  display: none;
}
.addorsubscribeform .sub-proceedbtn {
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #f3e2d7;
  color: #ed7d31;
  border: none;
  border-radius: 20px;
}
.addorsubscribeform .sub-proceedbtn:hover {
  cursor: pointer;
}
.addorsubscribeform .sub-proceedbtn:hover .sub-parrow {
  margin-left: 10px;
  transition: margin 500ms;
}
.sub-parrow {
  font-size: 12px;
  margin-left: 5px;
}
.subproceeddiv {
  padding-bottom: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.addorsubscribepaymentform .dropdownPropertyBasicSub {
  display: flex;
  width: 30% !important;
  padding: 0px !important;
  margin-bottom: 3px;
  flex-direction: column;
}
.spform .price-container {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  /* padding-right: 40px; */
}
.spform .price-container .label {
  color: #44546a;
}

.spform .sub-proceedbtn {
  display: flex;
  width: 120px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  /* background-color: #fbf9f7; */
  background-color: #ed7d31;
  color: #ffffff;
  border: none;
  border-radius: 20px;
}
.spform .sub-proceedbtn:hover {
  cursor: pointer;
}
.spform .sub-proceedbtn:hover .sub-parrow {
  margin-left: 10px;
  transition: margin 500ms;
}
.addorsubscribepaymentform
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item {
  font-size: 12px !important;
}
.addorsubscribepaymentform
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item.p-highlight {
  color: #ed7d31;
  background: #f3e2d7 !important;
}
.addorsubscribepaymentform .p-multiselect {
  height: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
}
.addorsubscribepaymentform
  .p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item {
  font-size: 12px;
  width: 100% !important;
  text-wrap: wrap !important;
}
.addorsubscribepaymentform .p-checkbox .p-checkbox-box.p-highlight,
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  background: #ed7d31;
  border: 1px solid #ed7d31;
}
.addorsubscribepaymentform .p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: #44546a;
}
.addorsubscribepaymentform h5 {
  font-size: 14px;
  margin-top: 0px;
  padding-left: 10px;
}
.addorsubscribepaymentform .dropdownPropertyBasicPayment {
  display: flex;
  width: 100% !important;
  padding: 0px !important;
  margin-bottom: 3px;
  flex-direction: column;
}

.addorsubscribepaymentform .dropdownContentPayment {
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-top: 12px;
}
.addorsubscribepaymentform .dropdownContentPaymentPlan {
  display: flex;
  flex-direction: row;
  width: 70% !important;
  gap: 30px;
  margin-top: 5px;
}
.dropdownPropertyBasicPaymentPrice {
  display: flex;
  margin-bottom: 3px;
  flex-direction: row;
  justify-content: flex-end;
}
.addorsubscribepaymentform .paymentbtndiv {
  display: flex;
  padding-right: 10px !important;
  justify-content: center;
  margin-bottom: 10px;
  padding-left: 25px;
}
.addorsubscribepaymentform .dropdownRoleStatePayment {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  margin-right: 40px;
  grid-gap: 10px;
  gap: 10px;
}
.addorsubscribepaymentform .dprsPayment {
  display: flex;
  flex-direction: column;
  width: 50% !important;
  gap: 1px;
  margin-top: 3px;
}
.addorsubscribepaymentform .labelstyle {
  font-size: 12px;
}
.SubBtnCard {
  flex: 1 1 0%;
  padding: 2px 2px;
  border-radius: 8px;
}

.mostPopular {
  font-family: var(--global-heading-font-family);
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 0%;
  padding: 4px 0;
  max-height: fit-content;
  border: none;
  background-color: var(--background-color-orange);
  color: var(--font-color-white);
  border-top-left-radius:10px ;
  border-top-right-radius: 10px;
}
.mostPopular-hover {
  /* background-color: var(--background-primary); */
  color: var(--font-color-black);
}

.card-header {
  color: var(--font-color-black);
  padding: 0px 25px;
}
.card-header-hover {
  color: var(--font-color-white);
}
.custom-header {
  font-size: 24px;
  font-weight: 600;
  padding: 28px 0px;
}
.card-header-img {
  width: 45px !important;
  height: 45px;
}
.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* When hovering, show the image */
.card-container:hover .card-image img {
  opacity: 1;
}
.subcard .p-card-footer {
  padding: 0 10px;
  display: flex !important;
  justify-content: flex-start !important;
  color: var(--font-color-gray) !important;
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 25px;
}

.custom-list {
  margin: 0;
  padding: 10px;
  list-style-type: none;
}
.custom-list li {
  position: relative;
  padding-left: 30px;
  color: var(--font-color-gray);
}
.custom-hover-list li {
  color: var(--font-color-white);
}
.custom-list-benefit-icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.subscribeNowPage .p-divider.p-divider-horizontal {
  height: 1px;
  background-color: #c5c6c7;
  width: 80%;
}
.card-title {
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  padding-top: 35px;
  align-items: center;
  gap: 10px;
  display: flex;
}
.card-title-custom {
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  padding-top: 35px;
  align-items: center;
  gap: 10px;
  display: flex;
  padding-bottom: 43px;
}

.annual-card-title-custom {
  padding-bottom: 60px;
}
.spacing-custom-annual {
  line-height: 23.5px;
}
.card-subtitle {
  text-align: center;
  padding: 10px;
  font-size: 16px;
  line-height: 20px;
}
.card-pricing {
  padding: 15px 25px 5px 18px;
  font-weight: bold;
  margin: 0;
  position: relative;
  width: max-content;
}
.backIcon {
  padding: 10px 10px 10px 0px;
  cursor: pointer;
}
.sub-card-pricing {
  font-size: 32px;
  font-weight: 600;
  position: relative;
  width: max-content;
  margin: 0;
}
.sub-card-per-month,
.sub-card-per-year {
  font-size: 18px;
}
.sub-total {
  color: #44546a;
  padding-right: 20px;
}

.dollar-sign {
  font-size: 20px;
  position: absolute;
  left: 5px;
  font-weight: 600;
}

.number {
  font-size: 20px;
  display: block;
}

.per-month {
  font-size: 14px;
  position: absolute;
  top: 20px;
  right: -50px;
  font-weight: 600;
}
.per-year {
  font-size: 14px;
  position: absolute;
  top: 20px;
  right: -45px;
  font-weight: 600;
  /* color: #44546a; */
  letter-spacing: 1px;
}
.plan-selection {
  display: flex;
  gap: 20px;
}
.plan-selection .p-field-checkbox > label,
.p-field-radiobutton > label {
  margin-left: 0.5rem;
  line-height: 1;
  font-size: 12px;
  color: #44546a;
}

.subscribe-btn {
  display: flex;
  width: 100%;
  justify-content: center;
  font-style: normal;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  /* text-transform: uppercase; */
  transition: all 0.3s ease;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  margin-top: 10px;
  /* letter-spacing: 1px; */
  /* background-color: #ED7D31; */
  background: transparent;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  font-family: var(--global-heading-font-family);
}

.subscribe-btn-hover {
  background-color: var(--background-primary);
  border: 1px solid white;
}
.benefitslabel {
  font-size: 14px;
  font-weight: 600;
  color: var(--font-color-black);
  font-family: var(--global-heading-font-family);
}
.benefitslabel-hover {
  color: var(--font-color-white);
}
.subscribeNowPage .p-radiobutton .p-radiobutton-box.p-highlight {
  background-color: #ed7d31 !important;
  border-color: #ed7d31 !important;
  outline: none !important;
}

.subscribeNowPage .p-radiobutton .p-radiobutton-box {
  outline: none !important;
  box-shadow: none !important;
}

.subscribeNowPage .p-radiobutton .p-radiobutton-box:focus {
  outline: none !important;
  box-shadow: none !important;
}

.subscribeNowPage .p-radiobutton input:focus + label {
  outline: none !important;
  box-shadow: none !important;
}
.panelContentPaymentAddSub
  .subchipContainer
  .p-chips
  .p-chips-multiple-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0px !important;
  width: 92%;
  max-height: 225px;
  overflow-y: auto !important;
  border: none !important;
  grid-gap: 5px;
  gap: 5px;
  background: none;
}

.panelContentPaymentAddSub .labelstyle {
  color: #44546a;
  letter-spacing: 0.5px;
}
.p-Input-input {
  font-size: 11px !important;
}
.paymentcompletepage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-switch-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 180px;
}
.subscribeNowPage .p-inputswitch {
  height: 45px;
  position: relative;
  z-index: 1;
  width: 100%;
  pointer-events: auto;
}
.subscribeNowPage .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.subscribeNowPage
  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider,
.subscribeNowPage .p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider,
.subscribeNowPage .p-inputswitch .p-inputswitch-slider {
  background: var(--background-color-orange);
  z-index: 99;
}
.subscribeNowPage
  .p-inputswitch.p-inputswitch-checked
  .p-inputswitch-slider:before,
.subscribeNowPage .p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 50%;
  border-radius: 20px;
  padding: 0px;
  transition: all 0.3s ease;
  height: 80%;
  margin-top: -18px;
}

.subscribeNowPage
  .p-inputswitch.p-inputswitch-checked
  .p-inputswitch-slider:before {
  transform: translateX(90%);
}

.switch-label-left,
.switch-label-right {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13px;
  color: var(--font-color-black);
  font-weight: 600;
  font-family: var(--global-heading-font-family);
  pointer-events: auto;
  cursor: pointer;
}
.checkedfont {
  color: var(--font-color-white);
}

.switch-label-left {
  left: 25px;
}

.switch-label-right {
  right: 25px;
}
.strikeoutvalue {
  color: var(--font-color-gray);
  text-decoration: line-through;
  padding: 0 5px;
  font-size: 15px;
}
.strikeoutvalue-hover {
  color: var(--font-color-white);
}
.us-national-map {
  max-width: 90%;
}
.usmapimg {
  display: flex !important;
  height: 50vh !important;
  min-height: 50vh !important;
  width: 60% !important;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 20px;
}
.subscriptionAlert {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
}
.subalertLabel {
  width: 83%;
  font-size: 14px;
  color: var(--font-color-orange);
  text-align: center;
}
.paymentemailerror {
  font-size: 12px;
  font-weight: normal;
  color: #ed7d31 !important;
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
}
.payment-complete-form {
  align-self: flex-start;
  padding:0px 5px;
  width: 50%;
  max-width: 50%;
  font-size: 12px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: visible;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin: 15px 0;
  font-size: 11px !important;
}

.payment-complete-form button {
  background: #44546a;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.payment-complete-form button:hover {
  filter: contrast(115%);
}

.payment-complete-form button:disabled {
  opacity: 0.5;
  cursor: default;
}

.payment-complete-form .spinner,
.payment-complete-form .spinner:before,
.payment-complete-form .spinner:after {
  border-radius: 50%;
}

.payment-complete-form .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.payment-complete-form .spinner:before,
.payment-complete-form .spinner:after {
  position: absolute;
  content: '';
}

.payment-complete-form .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #44546a;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.payment-complete-form .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #44546a;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

#dpm-annotation {
  align-self: center;
  color: #44546a;
  line-height: 20px;
  margin-bottom: 20px;
}

#dpm-integration-checker {
  display: inline;
  color: #44546a;
}

#payment-status {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;
  width: 40vw;
  max-width: 50vw;
  min-width: 500px;
  min-height: 380px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  opacity: 0;
  font-family:var(--font-family) !important ;
  color: #44546a;
  animation: fadeInAnimation 1s ease forwards;
  margin-top: auto;
  margin-bottom: auto;
}

#status-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.payment-complete-form h2 {
  margin: 0;
  color: #44546a;
  text-align: center;
}

.payment-complete-form a {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 600;
  display: block;
}
.payment-complete-form a:hover {
  filter: contrast(120%);
}
.payment-header-logo{
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.payment-logo{
  width: 100px;
  margin-left: 10%;
}
.checkout-main-div{
  width: 100%;
  height: 100%;
  display: flex;
  gap:20px;
  font-family:var(--font-family) !important ;
  font-weight: 400;
  justify-content: center;
  padding: 20px 10%;
}
.checkout-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 45%;
  max-width: 45%;
  max-height: max-content;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
}
.checkout-left-header{
  background-color: #44546a;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  color: #FFFFFF;
  padding: 10px 25px;
  font-weight: 600;  
  font-size: 14px;
}
.checkout-left-subheader{
  background-color: #dddddc6b;
  color: #525050;
  padding: 10px 25px;
  font-weight: 600;  
  font-size: 14px;
}
.checkout-left-main-content{
  font-weight: 600;
  padding: 15px 25px;
  font-size: 16px;
  color: #525050;
  display: flex;
  justify-content: space-between;
}
.checkout-left-main-content-plan{
  font-size: 12px;
  font-weight: 600;
  padding: 5px 25px;
}
.checkout-left-main-content-list{
  display: flex;
justify-content: space-between;
}
.selectionlistname{
font-size: 11px;
  color: #a39898;
  font-weight: 400;
  padding: 15px 25px 0px 25px;
}
.selectionlist{
  font-size: 11px;
    color: #a39898;
    font-weight: 400;
    padding: 15px 25px 20px 25px;
  }
.checkout-heading{
  font-weight: 600;
  padding: 15px 5px;
  font-size: 14px;
  color: #525050;
}
.dropdownPropertyBasicCheckout{
 display: flex;
 flex-direction: row;
 gap:10px;
 padding: 0px 5px;
}
.dropdownContentCheckout{
  display: flex;
  flex-direction: column;
  width: 50%;
}
.checkoutlabelstyle{
 font-size: 11px !important;
 font-weight: 600 !important;
 color: #525050 !important;

}

.dropdownContentCheckout .p-inputtext{
  padding: 5px !important;
  font-size: 11px !important;
}
.completepage-footer{
font-size: 14px;
  font-weight: 400;
  display: flex;
  text-align: center;
  line-height: 15px;
}
.completepage-header{
  display: flex;
  text-align: center;
}
.checkout-loader{
width: 100%;
display: flex;
justify-content: center;
align-items: center;
overflow:hidden;
}
.payment-main-bg-checkout{
  width: 100% !important;

}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fadeInAnimation {
  to {
      opacity: 1;
  }
}

/* Tablet view - 2 cards per row */
@media (max-width: 1100px) {
  /* .subchipContainer .p-chips .p-chips-multiple-container .p-chips-token {
    width: 90% !important;
  } */
  .subscribeNowPage h4 {
    font-size: 30px !important;
  }
  .chooseSubMainContainer {
    display: flex;
    flex-wrap: wrap;
  }
  /* .chooseSubMainContainer, */
  .addorsubscribepaymentform {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the cards */
  }
  .addorsubscribepaymentform .dropdownContentPaymentPlan {
    display: flex;
    flex-direction: row;
    width: 70% !important;
    grid-gap: 10px;
    margin-top: 5px;
  }
  .spleft{
    width: 60%;
  }
  .spright{
    width:100%
  }
 
}

@media (min-width:655px) and (max-width: 1100px) {
  .subcard {
    width: 33vw;
    max-width: 33vw;
    margin: 10px; 
  }
}
@media (max-width: 800px) {
  .checkout-main-div{
    display: flex;
    flex-wrap: wrap;
  }
  .checkout-left{
    width: 100%;
  max-width: 100%;
  }
 .payment-main-bg-checkout .payment-complete-form{
    width: 100% !important;
    max-width: 100% !important;
  }
  .payment-complete-form, #dpm-annotation, #payment-status {
    width: 60vw;
    min-width: initial;
    margin:20px 10px;
  }
}
/* Mobile view - 1 card per row */
@media (max-width: 600px) {
  .sub_footer{
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
  }
  .subscribeNowPage h4 {
    font-size: 26px;
  }
  .spleft,.spright{
    width: 100%;
  }

  .subchipContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .subselectionContainer {
    flex:1 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .chooseSubMainContainer,
  .addorsubscribepaymentform {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the cards */
  }
 
  .panelContentPaymentAddSub {
    padding: 10px;
  }
  .addorsubscribepaymentform .dropdownContentPayment {
    width: 100%;
  }
  .addorsubscribepaymentform .dropdownContentPaymentPlan {
    display: flex;
    justify-content: center;
  }
  .addorsubscribepaymentform .dropdownPropertyBasicPayment {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .payment-complete-form, #dpm-annotation, #payment-status {
    width: 80vw;
    min-width: initial;
    margin:20px 10px;
  }
  .payment-main-bg-checkout .payment-complete-form{
    width: 100% !important;
    max-width: 100% !important;
  }
  
}