.loging{
  width: 65.5%;
  min-height: 96.8vh !important;
  max-height: 96.8vh !important;
  box-shadow: 0px 3px 6px #00000029;
}
.loging .p-galleria .p-component{
  height:100% !important;
  max-height: 100% !important;
}
.alertl {
  font-family: 'Raleway Regular' !important;
  font-size: 14px !important;
  font-weight: normal !important;
  background-color: #d33f35; 
  color: white;
  padding: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.pi-exclamation-circle{
 margin: 10px 15px;
}
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: normal;
  float: right;
  font-size: 30px;
  line-height: 35px;
  cursor: pointer;
  transition: 0.3s;
}

.logingallery .p-galleria-caption .animateme{
  animation: animate 5s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
  background-color: #ED7D31;
  top: 40px;
  left: -20px;
  width: 10px;
  height: 200px;
  position: absolute;
  z-index: 9;
}


@keyframes animate {
  0% {
      width:0%;
      height:4px;
  }
  20% {
    width: 20%;
    height: 4px;
  }
  40% {
    width: 30%;
    height: 4px;
  }
  60% {
    width: 40%;
    height: 4px;
  }
  100% {
      width: 50%;
      height: 4px;
  }
}


.goldimg{
  margin-left: 5px;
}

.poweredBy{
  display:flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 5%;
}
.poweredBy b{
  font-family: 'Raleway Regular';
  font-size: 12px;
  font-weight: 400;
  line-height: 30px;
}
.error{
  font-family: 'Raleway Regular';
  font-size: 12px;
  font-weight: normal;
  color: #ed7d31;
}


.loginf .image{
  width: 100%;
height: 120px;
display: flex;
justify-content: center;
}
.logingallery .p-galleria-caption p:last-child{
  position: absolute;
  top:0;
  left: 0;
  z-index: 33;
}

.logingallery .p-galleria-caption{
  top:10%;
  left: 30%;
  width: 50%;
  height: 40%;
  font-family: 'Calibri reg';
  font-size: 24px;
  color: #44546a !important;
  z-index: 10;
}
.forgotp{
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.terms{
  padding: 10px 10px 50px 0px;
}
.terms .termstext {
  margin-left: 10px;
  font-family: 'Raleway Regular';
  font-size: 14px;
  font-weight: 600;
  color: #44546a;
}

.tandc{
  font-family: 'Raleway Regular';
  font-size: 14px;
  font-weight: bold;
  color: #44546a;
  text-decoration: underline;
}
.mainHeader .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators{
  background-color: transparent;
}
.mainHeader{
  height: 100%;
  width: 100%;
}

.mainlogin{
  height: 100% !important;
  display: flex;
  justify-content: space-between;
}
.pi-eye{
  color: #44546a!important
}

  .loginf .image img{
    height: 150px;
    width: 180px;
}
#loginSubmit{
    height: 50px !important;
}

 .tcal{
  display: flex;
  justify-content: space-between;
 }

 .tcal>.p-inputtext{
  font-family: 'Raleway Regular';
    font-size: 14px;
    font-weight: normal;
    color: #44546a;
 }
 .tcal div{
  width: 49%;
 }
 .sdrop .inputsignup{
  width: 48%;
  display: flex;
 }
  .loginf>form{
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    height: 100%;
    justify-content: center;
    padding: 40px 70px;
    font-family: 'Calibri reg';
    font-size: 16px;
    font-weight: 600;

   
  }
  .loginf{
   width: 34.15%;
   background-color: #fff;
   box-sizing: border-box;
  }
  .logintext p:nth-child(1){
    font-family: 'Raleway Regular';
    font-size: 14px;
    font-weight: 600;
    color: #44546a;
    margin: 25px 0px;
    
  }
  .logintext p:nth-child(2) {
    font-family: 'Calibri reg';
    font-size: 14px;
    font-weight: 400;
    color: darkgray;
    
  }

.mainHeader .p-galleria .p-galleria-indicators .p-galleria-indicator button{
  width: 25px;
  height: 8px;
  border-radius: 10px;

}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button{
  background-color: #acc8ef !important;

}

.mainHeader .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button{

  background-color: #44546a !important;
}
  
  .p-galleria-item-container{
    background-color:#fff !important ;
    width: 100%;
  }
  .p-galleria-item-container > .p-galleria-item{
    max-width: 100%;
  }

  .logingallery ,.p-galleria , .p-galleria-component ,.p-galleria-content ,
  .p-galleria-item-wrapper , .p-galleria-item-container{
    height: 100% !important;
    max-height: 100% !important;
  }

  .gi{
    width :100%;
    max-height: 75%;
    height: 75%;
    object-fit:contain;
  }
  
  .nl{
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

.loginf .p-field{
  margin-bottom: 2rem;
}
  .p-field .p-inputtext:enabled:hover{
    border-color:#44546a !important
  }
  .p-field .p-inputtext:enabled:focus{
    border-color:#44546a !important
  }
  .p-field .p-inputtext{
    width : 100%;
    padding: 10px;
    font-family: 'Raleway Regular';
    font-size: 14px;
    font-weight: normal;
    color: #44546a;
    border-radius: 5px !important;

  }
  .p-password{
    width: 100% !important;
    font-family: 'Raleway Regular';
    font-size: 14px;
    font-weight: normal;
    color: #44546a;
    border-radius: 5px !important;

  }

  .inputsignup .p-dropdown-label, .p-dropdown-item{
    font-family: 'Raleway Regular' !important;
    font-size: 14px !important;
    font-weight: normal !important;
    color: #44546a !important;
  }
  .btnsignup{
    width: 30%;
  }
  .btnLogin, .btnsignup{
    background-color: #44546a !important;
    border: none !important;
cursor: pointer !important;
  }
  .btnLogin{
    border-radius: 8px !important;
  }
.fpwdl{
  font-family: 'Raleway Regular' !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: black !important;
  padding-top: 35px;
  padding-bottom: 20px;
    display: flex;
    justify-content: center;
}
  


 .btnLogin >.p-button-label ,.btnsignup>.p-button-label{
  font-family: 'Raleway Regular';
  font-size: 14px;
  font-weight: 600;
  color: white;
 }

.inputn{
  font-family: 'Raleway Regular' !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #44546a !important;
}
.Reset{
  display:flex;
  flex-direction: column;
  gap: 10px;
}
  @media only screen and (max-width: 768px) {
    .mainHeader>section{
      display: grid !important;
      background-repeat: no-repeat;
      margin:0 !important;
      width:100%;
      
    }
    .gi{
      width:100%;
      height:50%;
    }
    .loginf .image img{
    }
    .labs{
        position: relative;
    }
    .labs .pi .pi-user{
       position: absolute; 
       top: 0;
       right: 0;
    }
    .mainlogin{

      display: flex;
      flex-direction: column !important;
    }
    .loging{
      width: 100% !important;
      min-height: 50% !important;
      max-height: 50% !important;
    }
    .loginf {
      width: 100% !important;
    }
  }
.spinnerAlign{
  display: flex;
  justify-content: center;
}
@media (max-width: 1120px) {
}
@media (max-width: 820px) {
  .loginf>form{
    padding: 40px!important;
  }
}
@media (max-width: 650px) {
  }