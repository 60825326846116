/* loaderlogo */
.logoloaderimg{
    width: 100px;
  }
  .logoloader{
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top:30px;
  }
  .loading {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    bottom: 0;
}
  .loadingdot {
    animation: dot ease-in-out 2s infinite;
    background-color:#44546A;
    display: inline-block;
    height: 20px;
    margin: 10px 5px;
    width: 8px;
    height: 8px;
    border-radius: 10px;
  }
  .loadingdot:nth-of-type(2) {
    animation-delay: 0.2s;
  }
  .loadingdot:nth-of-type(3) {
    animation-delay: 0.4s;
  }
  @keyframes dot {
    0% { background-color: #44546A; transform: scale(1); }
    50% { background-color: #ED7D31; transform: scale(1.3); }
    100% { background-color:#44546A; transform: scale(1); }
  }
  .feedsloader{
    margin:10px;
    display: flex;
  }
 .listloader{
  flex:1;
 }
 .listcontentloader{
  margin-bottom: 10px;
  margin-left: 20px;
 }
 .feedsl{
  width:100%;
  height: 100%;
  overflow: hidden;
  }
  /* .overviewl{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    justify-content: space-between;
    box-shadow: 0px 0px 2px 0px #00000029;
    height: calc(92%);
    width: 16% !important;
    height: calc(50%);
    overflow: hidden;
    margin-top: 2px;
    margin-bottom: 0px;
} */
.overviewl{
  z-index: 999;
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px 0px #00000029;
  display:flex;
  flex-direction: column;
  justify-content: space-around;
  height: calc(18vh) !important;
  min-height: 90% !important;
  /* max-height: 130px !important; */
  flex:1 0 16%;
  margin-bottom: 0px;
}
@media (max-width: 1024px) {
  .overviewl{
      flex-basis: calc(33.33% - 20px);
      margin-bottom: 10px;
      min-height: 100px !important;
  }
}

@media (max-width: 768px) {
  .overviewl {
      flex-basis: calc(50% - 20px);
      margin-bottom: 10px;
      min-height: 100px !important;

  }
}
@media (max-width: 450px) {
  .overviewl {
      flex-basis: calc(100% - 20px);
      margin-bottom: 10px;
      min-height: 100px !important;

  }
}