 body{
  /* background-color: #f6f6f6; */
  padding: 10px;
  /* height: 100%; */
  overflow: visible !important
}
#root {
  background-color: #fff;
  /* height: 100%; */
  color: #44546a;
  font-family: "Raleway Regular";
  font-weight: 400;
  }
 
.App {
    display: flex;
    /* height:100%; */
    width: 100%;
    }
    .pointer{
      cursor: pointer;
    }
    .mr5{
      margin-right: 5px;
    }
    .mt10{
      margin-top: 10px;
    }
    .mt5{
      margin-top: 5px;
    }
    .mr10{
      margin-right: 10px;
    }
    .ml10{
      margin-left: 10px;
    }
    .ml5{
      margin-left: 5px;
    }
    .w48{
      width: 48%;
    }
    .tdnone{
      text-decoration: none;
    }
    .brnone{
      border: none;
    }
    .bgnone{
      background:none ;
    }
    .fr{
      float: right;
    }
    .pt-10{
      padding-top: 10px;
    }
    .p-0{
      padding: 0;
    }
    .m-0{
      margin: 0;
    }
    .prnone{
      padding-right: 0;
    }
    .g-5{
      gap:5px;
    }
   .g-10{
    gap:10px;
   }
   .w100{
    width: 100%;
   }
   .minw100{
    min-width: 100%;
   }
   .maxw100{
    max-width: 100%;
   }
   .minh100{
    min-height: 100%;
   }
   .maxh100{
    max-height: 100%;
   }
   .h100{
    height: 100%;
   }
    .fontfamilyCalibri{
      font-family:"Calibri"
    }
    .fontweight600{
      font-weight: 600;
    }
    .fontweight400{
      font-weight: 400;
    }
    .font07rem{
    font-size: 0.7rem;
    }
    .font18{
      font-size: 18px;
    }
    .font12{
      font-size: 12px;
    }
    .font13{
      font-size: 13px;
    }
    .font14{
      font-size: 14px;
    }
    .dflex{
      display: flex;
    }
    .j-fe{
     justify-content: flex-end;
    }
    .j-c{
      justify-content: center;
    }
    .orange{
      color: #ED7D31;
    }
    .reqColor{
      color:#FF0000
    }
    .white{
      color: #FFFFFF;
    }
    .tl{
      text-align: left;
    }
    .tc{
      text-align: center;
    }
    .fdc{
      flex-direction: column;
    }
    .fdr{
      flex-direction: row;
    }
.mainPageContainer{
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.icontent{
margin-top: 60px;
position: relative;
}
.pcontent {
  width: calc(100% - 80px);
  margin-left: 80px;
  }
.numberfont{
    font-family: 'Calibri';
    font-size: 12px;
    font-weight: 400;
  }
a {
    color: #44546a;
 }
/* sideBar */
.leads-sidebar{
  width: 33% !important;
}
/* sidebar */
.leftmenu{
  height: 100%;
}
.leftmenu .logo{
  /* margin-bottom: 20px; */
  width: 60px;
}
.lmenuitemac{
  position: relative;	
  /* transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);	 */
width: 100%;  
padding: 5px;
gap:8px;
margin-bottom: 5px;
border-left: 2px solid transparent;
border-bottom: 2px solid transparent;
box-sizing: border-box;
font-family: 'Raleway Regular';
text-decoration: none;
font-size: 14px;
text-align: center;
}
.sideicons{
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
height: 90%;
}
.sideiconsHelp{
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-end !important;

}
.lmenuitemac img{
  width:35px;
  height:30px;
  min-height: 30px;
  max-height: 30px;
  overflow: hidden;
  /* margin-bottom: 5px; */
}
.lmenuitemactiveac,.lmenuitemac:hover{
  border-left: 2px solid #ED7D31 !important;
  color: #ED7D31;
}
.from_bottomac {
  position: relative;
 display: flex;
 flex-direction: column;
 align-items: center;
  font-weight: 600;
 font-size:10px;
  color: #383736;
  border-radius: 4px;
  /* text-transform: uppercase; */
  outline: 0;
  background: none;
  z-index: 1;
  cursor: pointer;  
  height: 36px;
  overflow: hidden;

}
.from_bottomac_active {
  position: relative;
 display: flex;
 flex-direction: column;
 align-items: center;
  font-weight: 700;
 font-size:10px;
  color: #383736;
  border-radius: 4px;
  /* text-transform: uppercase; */
  outline: 0;
  background: none;
  z-index: 1;
  cursor: pointer;  

}
.pageiconlabel{
  margin-top: 5px;
  position: relative;
color:#ED7D31;
text-align: center;

}
.pageiconlabelhover{
  margin-top: 5px;
  position: relative;
  visibility: hidden;
  font-weight: 700;
  font-size:10px;
   color: #383736;
opacity:0;

}
.from_bottomac .pageiconlabelhover {
  top: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 8px;
}
.from_bottomac:hover{
   overflow: visible; 
  height: 50px;
  transition:all 0.3s ease-in-out 0.02s ;
}
.from_bottomac:hover .pageiconlabelhover {
  visibility: visible;
  opacity: 1;

}
.lmenu {
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 2px 0px #00000029;
  -moz-box-shadow: 0px 0px 2px 0px #00000029;
  box-shadow: 0px 0px 2px 0px #00000029;
  padding: 0px 0px 10px 0px;
  position: fixed !important;
  height: calc(100% - 10px);
  z-index: 2;
  }

/* sidebar */
.p-sidebar .p-sidebar-close, .p-sidebar .p-sidebar-icon:focus{
  box-shadow: none !important;
  background-color: transparent !important;
}

/* header */
.headertop{
  background-color: #fff;
  width: calc(100% - 100px) !important;
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 5px;
  -webkit-box-shadow: 0px 0px 2px 0px #00000029;
  -moz-box-shadow: 0px 0px 2px 0px #00000029;
  box-shadow: 0px 0px 2px 0px #00000029;
box-sizing: border-box;
margin-bottom: 10px;
position: fixed !important;
z-index: 999;
}
.hleft{
  display: flex;
  align-items: center;
}
.hright{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.hright p{
  margin: 0px 10px 0px 0px ;
  font-size: 14px;
  line-height: 14px;
}
.hrightaction{
font-size:14px;
font-family: 'Raleway Regular';
}
.headerimg{
  margin-right: 10px;
}
.headertitle{
  font-size: 25px;
  font-weight: 600;
  margin:0px;
}

/* toast */
.toastmsg{
  width: 100% !important;
  display: flex !important;
  justify-content: center;
  margin-bottom: 10px;
  height: 35px !important;
}
.p-inline-message .p-inline-message-text {
  font-size: 12px !important;
  font-family: 'Raleway Regular';
}

/* paginationtbn */
.paginationLead{
  display: flex;
  justify-content: center;
  margin: 20px 5px;
  gap: 20px;
}
.paginationLead > button{
  background: #ED7D31 0% 0% no-repeat padding-box;
  border: 1px solid #ED7D31;
  border-radius: 4px;
  min-width: 100px;
  color: #eee !important;
}
.paginationLead > .MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}
.paginationLead > button:hover{
  background: #ebb591 0% 0% no-repeat padding-box;
  border: 1px solid #e8a477;
  color: #f7e734;
}

/* inputtext */
.p-inputtext {
  font-size: 11px !important;
  font-family: 'Raleway Regular';
}
.p-inputtext:enabled:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color:#FBB700 !important;
}

.p-inputtext:enabled:hover{
  border-color:#FBB700 !important;
}

/* chat */
.feedbackicondivmain{
  right: 2%;
  bottom:5%;
  position: fixed;
  width: 25%;
  height: 80%;
  padding: 10px;
  z-index: 999;
}
.feedbackicondivnew{
  right: 2%;
  bottom:5%;
  position: fixed;
  width: 25%;
  height: 50%;
  padding: 10px;
  z-index: 999;
}
.feedbackiconfloat{
  right: 0;
  bottom: 0;
  position: fixed;
    z-index: 9;
    width: 60px;
    cursor: pointer;
}
.feedbackicon{
  width: 60px;
  cursor: pointer;
}
.feedbackcontainer{
  border-radius: 20px;
  height:100%;
  position: relative;
  display:flex ;
  flex-direction: column;
  gap:20px;
  z-index: 9;
  justify-content: center;

}
.topfeedbackdiv{
  background-color: #ED7D31;
  border-radius: 20px;
  height: 100%;
  padding-top: 60px;
}
.mainfeedbackdiv{
 height: 100%;
 background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 7px 14px #32325D1A;
border-radius: 20px;
opacity: 1;
}
.logonewheader{
width: 60px;
}
.logodiv{
  width: 100%;
  /* height: 13%; */
  height: 13% !important;
  position: absolute;
  z-index: 999;
  top:-35px;
  display: flex;
  align-items: center;
}
.logodivloader{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.feedbacklogoheader{
  border: 10px solid #ED7D31;
  border-radius: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 0px 6px #0000002B; */
  opacity: 1;
  /* width: 80px; */
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 2px 0px;
 
  }

/* confirmationdialog */
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 20px;
  font-family: 'Calibri';
}
.p-dialog .p-dialog-header {
  background-color: #ED8036 !important;
  color: #ffff !important;
  font-family: 'Calibri' !important;
}
.p-dialog-header{
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
.p-dialog-footer{
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;

}
.dealfooter{
  display: flex;
  justify-content: center;
  gap: 20px;
}
.pcontent .MuiButton-root {
  border: 1px solid #ed7d31;
  color: #ed7d31;
  text-transform: capitalize;
  height: 80%;
  font-size: 12px !important;
  font-family: "Raleway Regular" !important;
  font-weight: 600 !important;
  padding-bottom: 3px;
}
.alignnotes {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dealbtn {
  color: #fff !important;
  background: #ed7d31 0 0 no-repeat padding-box !important;
  border: 1px solid #ed7d31 !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  cursor: pointer !important;
  font-weight: 400 !important;
  justify-content: center !important;
  width: 100px !important;
  height: 38px !important;
}

/* checkbox */
.p-checkbox .p-checkbox-box.p-highlight,
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  background: #ed7d31 !important;
  border-color: #ed7d31 !important;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #ed7d31 !important;
}

/* dropdown */
.p-dropdown:not(.p-disabled).p-focus{
  border-color: #ed7d31;
}

/* skeletonloader */
.titleloader{
  display:flex;
  justify-content: space-between;
   margin: 10px;
   height: 10% !important;
}
.contentloader{
   margin: 10px !important;
   height: 80% ;
}

/* tabview */
.topText .p-tabview-nav-link {
  font-size: 14px;
  color: #ed7d31 !important;
  padding: 20px !important;
}
.gridtab .p-tabview-selected {
  border-bottom: 2px solid #ed7d31;
  color: #ed7d31 !important;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff !important;
  border-color: #ed7d31 !important;
  color: #ed7d31 !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link{
  padding: 10px !important;
  border-width: 0 0 1px 0 !important;
  /* border-color: #ED7D31 !important; */
  /* margin: 0 0 0 0 !important; */
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}
.gridtab .p-tabview-selected .p-tabview-nav-link {
  color: #ed7d31 !important;
  border-bottom: 1px solid #ed7d31 !important ;
}
.gridtabInsAC .p-tabview-selected {
  border:none;
}
.gridtabInsAC .p-tabview-nav li .p-tabview-nav-link{
  padding: 0;
}
.gridtabInsAC .p-tabview-title{
  width: 100%;
}
.gridtab {
  width: 100%;
  font-family: "Raleway Regular";
}
.gridtabInsAC {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  background-color: #ed7d31;
}
.gridtabInsAC .p-tabview-panels {
  padding: 0px;
  width: calc(90%);
  -webkit-box-shadow: 0px 0px 2px 0px #00000029;
  -moz-box-shadow: 0px 0px 2px 0px #00000029;
  box-shadow: 0px 0px 2px 0px #00000029;
  padding-bottom: 10px;
  font-family: "Raleway Regular";
}
.gridtabInsAC .p-tabview-nav {
  flex-direction: column;
  border: none;
  width: calc(10% - 10px);
  font-family: "Raleway Regular";
}
.gridtab .p-tabview-nav li .p-tabview-nav-link {
  background-color: transparent;
  border: none;
  font-size: 13px;
}

/*multiselect */
.p-multiselect{
  box-shadow: none !important;

}

.p-multiselect:hover,.p-dropdown:hover{
  box-shadow: none !important;
  border: 1px solid orange !important;
}
.p-dropdown :focus, .p-dropdown.p-focus,.p-multiselect:focus,.p-multiselect.p-focus {
  box-shadow: none !important;
  border: 1px solid orange !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus ,
.p-dropdown:not(.p-disabled).p-focus{
  box-shadow: none !important;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #ed7d31 !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  /* background: #bab9b9; */
  background: #ebb591 !important;
  box-shadow: none !important;
  
}
/* divider */
.p-divider.p-divider-horizontal {
  height: 1px;
  background-color: #c5c6c7;
}
/* nodataavailable */
.nodataavailable{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: 'Calibri';
}


/* orgdropdown or icon */
.orgicon {
  position: absolute;
  z-index: 1;
  right: 2%;
  top: 15px;
  width: 8%;
  cursor: pointer;
}
.orgicon .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
font-size: 12px !important;
}
/* highcharts-container */
.chart-wrapper {
  position: relative;
  width: 100%; height: 100%;

 }
 
 .chart-inner {
 position: absolute;
     width: 100%; height: 100%;

 }
 .highcharts-container{
  display: flex;
   width: 100% !important;
   justify-content: center;
   max-height: 100% !important;
   }
   .highcharts-container svg {
    display: flex;
   max-width: 100% !important;
   max-height: 100% !important;
   font-family: 'Calibri' !important;
   font-size: 11px !important;
   margin-top: 1.5% !important;
   }
   .map-container {
    display: flex;
    max-width: 100% !important;
    align-self: center;
    justify-self: center;
    margin-bottom: 1.5%;
}
.recommendedBuyerSlider{
  width: 55% !important;
}
.addUserDilog,.viewNotesDialog, .viewAllLeadsNotesDialog {
width: 50vw;
margin-left: '70px'
}
.leadNotesDialog,.addNotesDialog, .ConfirmationDialog, .statusChangeDialog{
width: 30vw;
margin-left: '70px'
}

/* for menu */
@media only screen and (min-width: 200px) and  (max-width: 600px)  {
  .headertop{
    width:calc(100% - 20px) !important;
    position: static !important;
  }
  .addUserDilog,.viewNotesDialog, .viewAllLeadsNotesDialog {
    width: 90vw !important;
    }
    .leadNotesDialog,.addNotesDialog, .ConfirmationDialog, .statusChangeDialog{
    width: 70vw !important;
    }
  .pcontent{
    width: 100%;
    margin-left: 0px;
  }
  .icontent{
    width: 100% !important;
    margin-top: 0px;
  }
  /* sidebar */
  .lmenu {
    height: 100%;
    width: 100%;
    margin-bottom: 10px;
    z-index: 999;
    }
    .lmenuitemactiveac,.lmenuitemac:hover{
      border-left: none !important;
      color: #ED7D31;
    }
    .sideicons{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      }
  .lmenuitem {
    font-size: 8px;
  }
 .lmenuitemac{
  width: 15%;
 }



 /* dropdown */

  .mcharts {
    width: 100% !important;
    height: 100%;
  }
  .logo {
    height: 30px;
    width: 20%;
  }
  .timelinecontent{
    max-width: 30%;
  }
  .timelinecontent{
    max-width: 90%;
    font-size: 8px !important;
    font-weight: bold;
   
  }
  .p-timeline-event{
    width: 50px;
  }
  .leadscard .image{
    width: 50px;
  }
  .leadscard .viewDetails{
    font-size: 10px !important;
  }
  .side-content{
    width: 30% !important;
  }
  .leadscard .header .main-content{
    width: 70%;
  }
  .leadscard .dropdown, .leadscard .rbbutton{
    font-size: 10px !important;
  }
  .leadscard .content .p-inputtext{
    font-size: 12px !important;
    /* padding: 2px 4px; */
  }
  .fifty .p-inputtext{
  width: 100%;
  }
  
  }
  /* only for conflict in phones and laptop  */
  @media only screen and (min-width: 600px) and  (max-width: 820px){
    .leads-sidebar{
      width: 60% !important;
    }
    .recommendedBuyerSlider{
      width: 100% !important;
    }
    .headertop{
      width: 100%;
      /* position: static !important; */
    }
    .pcontent{
      width: 100%;
      margin-left: 0px;
    }
    .icontent{
      width: 100% !important;
      margin-top: 0px;
    }
    /* sidebar */
    .lmenu {
      height: 100%;
      width: 100%;
      margin-bottom: 10px;
      z-index: 999;
      }
      .lmenuitemactiveac,.lmenuitemac:hover{
        border-left: none !important;
        color: #ED7D31;
      }
      .logo {
        height: 30px;
        width: 20%;
      }
      .sideicons{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        }
    .timelinecontent{
        max-width: 70%;
      }
      /* .fixed > .fifty > div{
        width: 40% !important;
      } */
      .marginlr{
        max-width: 100px !important;
      }
  }
  @media only screen and  (max-width: 820px)   {
    .addUserDilog,.viewNotesDialog, .viewAllLeadsNotesDialog {
      width: 70vw;
      }
      .leadNotesDialog,.addNotesDialog, .ConfirmationDialog, .statusChangeDialog{
      width: 50vw;
      }
    .leads-sidebar{
      width: 60% !important;
    }
    .recommendedBuyerSlider{
      width: 90% !important;
    }
  .dealanalyticsnew,
  .leadsIntelligence,
  .userengagement {
    flex-direction: column;
  }
 
  .marketpage {
    flex-direction: row;
  }
  .headertop{
    width:100% !important;
    position: static !important;
  }
  .lmenu {
    width: 100%;
    position: static !important;
    height: 140px;
    z-index: 999 !important;
  }
  .leftmenu {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .lmenuitem {
    max-width: 20%;
    font-size: 10px;
  }
  .lmenuitemactiveac,.lmenuitemac:hover{
    border-left:none !important;
    color: #ED7D31;
  }
  .logo {
    object-fit: contain;
    height: 40px;
    width: 20%;
  }
  .lmenuitem img {
    width: 25px;
  }
  /* .pcontent {
    width: 100%;
    margin: 10px 0 0 0;
    padding: 0;
  } */
  .headertop {
    width:100% !important;
    position: static;
  }
  .overflowscroll {
    margin-top: 10px;
  }
  .headerimg {
    width: 30px;
  }
  .headertitle {
    font-size: 16px;
  }
  .live {
    width: 60px;
    object-fit: contain;
    height: 35px;
  }
  .marketpagecontent {
    flex-direction: row;
  }
  .feeds,
  .mchartsFourchart {
    width: 100% !important;
  }
  .mcharts {
    width: calc(50% - 4px);
    height: 100%;
  }
  /* for leads */
  /* .timeline {
    position: fixed;
    margin-top: 10px;
  
  } */
  /* .fixed{
    position: static;
    margin-top: 10px;
    width: 100% !important;
    align-items: baseline;
    gap: 10px;
  } */
  .leadsProperty{
    margin-top: 10px;
  }
  .leadscard .header{
    width: 100%;
  }
  .side-content{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap:10px;
  }
  .leadscard .image,.leadscard .viewDetails{
    margin: 0;
    font-size: 12px;
  }
  .leadscard .title,.leadscard .address,.state, .lastSale{
    font-size: 12px;
    line-height: 12px;
  }
  .leadscard .dropdown,.leadscard .rbbutton{
    width: 50%;
    margin: 0;
    font-size: 12px;
  
  }
  .leadscard .content{
    justify-content: space-between;
    gap:10px;
    padding: 4px;
  }
  .fifty{
    width: 75%;
  }
  .justify-right{
    width: 25%;
  }
  .p-input-icon-right{
    width: 100%;
  }
  /* .fixed > .fifty > div{
    width: 100%;
  } */
  .marginlr{
    max-width: 100px !important;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    gap: 2px;
  }
  .marginl{
    font-size: 12px;
    margin: auto;
  }
  .leadsProperty .leadscard{
    width: 50%;
  }
  
  /* for userengagement */
  .userengagementcontent > .ucharts {
    width: 100%;
  }
   /* reportbuilder */
   .reportBuilderContent{
    margin-top: 0px !important;
  }
  .reportBuilderContent > .labelstyle{
    font-family: 'Raleway Regular';
    font-size: 10px;
  }
  .reportBuilderContent .gridtab .p-tabview-nav li .p-tabview-nav-link {
    background-color: transparent;
    border: none;
    font-size: 12px;
  }
  .reportBuilderContent .dropdownContent{
    width: 30%;
    margin-bottom: 10px;
  }
  .reportBuilderContent .dropdownPropertyBasic{
    display: flex;
    flex-wrap: wrap;
  }
  .reportBuilderContent .topText{
    font-size: 12px;
    font-family: "Raleway Regular";
  }
  
  .reportBuilderContent .topText .p-tabview-nav-link {
    font-size: 12px;
   
  }
  .reportBuilderContent .basicContainer{
    display:grid;
    width: 100%;
    }
    .reportBuilderContent .propertyContainerOne{
      width: 100%;
    }
    .reportBuilderContent .propertyContainerTwo{
      width: 100%;
    }
  }
  @media only screen and (min-width: 820px) and (max-width: 1200px)  {
    .headertop{
      width:calc(100% - 110px) !important;
    }
    .addUserDilog,.viewNotesDialog, .viewAllLeadsNotesDialog {
      width: 60vw;
      }
      .leadNotesDialog,.addNotesDialog, .ConfirmationDialog, .statusChangeDialog{
      width: 40vw;
      }
    .recommendedBuyerSlider{
      width: 60% !important;
    }
    .leads-sidebar{
      width: 40% !important;
    }
    .pcontent{
      width: calc(100% - 90px);
      margin-left: 90px;
    }
  .lmenuitem {
    font-size: 10px;
  }
  .leftmenu .logo {
    width: 80px;
  }
  .headertitle {
    font-size: 20px;
  }
  .headerimg {
    width: 40px;
  }
  
  }
  @media only screen and (min-width: 1200px)  {
    .mioverviewMain{
      padding: 5px;
    } 
  }
  @media (max-width: 650px) {
   .leads-sidebar{
    min-width: 90% !important;
   }
    }